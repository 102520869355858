<template>
    <div class="roulette-game">
        <transition name="fade" mode="out-in">
            <div v-if="rouletteData.game !== null && ['created', 'pending', 'fairness'].includes(rouletteData.game.state) === true" class="game-info" key="info">
                <div v-if="rouletteData.game.state === 'fairness'" class="info-fairness">
                    Waiting for eos block
                    <span>#{{ rouletteData.game.fair.blockNum }}</span>
                </div>
                <div v-else class="info-countdown">
                    Rolling
                    <div class="countdown-text">
                        <span>{{ rouletteCountdownText.split('')[0] }}</span>
                        <span>{{ rouletteCountdownText.split('')[1] }}</span>
                        <span>.</span>
                        <span>{{ rouletteCountdownText.split('')[2] }}</span>
                        <span>{{ rouletteCountdownText.split('')[3] }}</span>
                    </div>
                </div>
            </div>
            <div v-else class="game-selector" key="selector"></div>
        </transition>

        <div class="game-reel" v-bind:style="rouletteReelStyle">
            <div v-for="i in 8" v-bind:key="i" class="reel-row">
                <img src="@/assets/img/roulette/red.webp" />
                <img src="@/assets/img/roulette/black.webp" />
                <img src="@/assets/img/roulette/red.webp" />
                <img src="@/assets/img/roulette/black.webp" />
                <img src="@/assets/img/roulette/red.webp" />
                <img src="@/assets/img/roulette/black.webp" />
                <img src="@/assets/img/roulette/bait_red.webp" />
                <img src="@/assets/img/roulette/green.webp" />
                <img src="@/assets/img/roulette/bait_black.webp" />
                <img src="@/assets/img/roulette/red.webp" />
                <img src="@/assets/img/roulette/black.webp" />
                <img src="@/assets/img/roulette/red.webp" />
                <img src="@/assets/img/roulette/black.webp" />
                <img src="@/assets/img/roulette/red.webp" />
                <img src="@/assets/img/roulette/black.webp" />
            </div>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';
    import { mapGetters } from 'vuex';

    export default {
        name: 'RouletteGame',
        mixins: [
            mixins
        ],
        data() {
            return {
                rouletteCountdownRepeater: null,
                rouletteCountdownText: '0000',
                rouletteOrder: [1, 14, 2, 13, 3, 12, 4, 0, 11, 5, 10, 6, 9, 7, 8],
                rouletteReelStyle: { transform: 'translate(0px, -50%)', transition: 'none' }
            }
        },
        methods: {
            rouletteStartCountdown() {
                const timeEnding = new Date(this.rouletteData.game.createdAt).getTime() + (1000 * 13);
                const timeLeft = Math.floor((timeEnding - (new Date().getTime() + this.generalTimeDiff)) / 10);

                this.rouletteCountdownText = String(timeLeft < 100 ? '00' + timeLeft : timeLeft < 1000 ? '0' + timeLeft : timeLeft);

                if(timeLeft <= 0) {
                    this.rouletteCountdownText = '0000';
                } else {
                    this.rouletteCountdownRepeater = requestAnimationFrame(this.rouletteStartCountdown);
                }
            }
        },
        computed: {
            ...mapGetters([
                'soundVolume',
                'soundRouletteStart',
                'soundRouletteEnd',
                'generalTimeDiff',
                'rouletteData'
            ])
        },
        watch: {
            'rouletteData.game': {
                handler(data, oldData) {
                    if(data.state === 'created') {
                        const index = this.rouletteOrder.indexOf(this.rouletteData.history[0].outcome);

                        this.rouletteReelStyle = { transform: 'translate(' + (5575 - 125 * index)  + 'px, -50%)', transition: 'none' };

                        this.rouletteStartCountdown();
                    } else if(data.state === 'rolling') {
                        const index = this.rouletteOrder.indexOf(this.rouletteData.game.outcome);
                        const offset = Math.abs(parseInt(this.rouletteData.game._id.substr(0, 8), 16)) % 9;

                        const timeEnding = new Date(this.rouletteData.game.updatedAt).getTime() + 5000;
                        let timeLeft = timeEnding - (new Date().getTime() + this.generalTimeDiff);
                        timeLeft = timeLeft > 0 ? timeLeft : 0;

                        this.rouletteReelStyle = { transform: 'translate(' + (-3750 - 125 * index - (12.5 * offset))  + 'px, -50%)', transition: 'transform ' + timeLeft / 1000 + 's cubic-bezier(0.05, 0.85, 0.25, 1)' };
                        this.soundPlay(this.soundVolume, this.soundRouletteStart);
                        
                        setTimeout(() => {
                            this.rouletteReelStyle = { transform: 'translate(' + (-3800 - 125 * index)  + 'px, -50%)', transition: 'transform 0.2s linear' };
                            this.soundPlay(this.soundVolume, this.soundRouletteEnd); 
                        }, timeLeft + 500) 
                    }
                },
                deep: true
            }
        },
        beforeDestroy() {
            cancelAnimationFrame(this.rouletteCountdownRepeater);
        }
    }
</script>

<style scoped>
    .roulette-game {
        width: 100%;
        height: 150px;
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 20px;
        border-radius: 18px;
        background: #11141f;
        overflow: hidden;
    }

    .roulette-game::before {
        content: '';
        width: 50px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 18px 0 0 18px;
        background: linear-gradient(90deg, #11141f 0%, rgba(17, 20, 31, 0) 100%);
        z-index: 1;
    }

    .roulette-game::after {
        content: '';
        width: 50px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0 18px 18px 0;
        background: linear-gradient(270deg, #11141f 0%, rgba(17, 20, 31, 0) 100%);
        z-index: 1;
    }

    .roulette-game .game-info {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        border-radius: 18px;
        background: rgba(17, 20, 31, 0.7);
        z-index: 5;
    }

    .roulette-game .game-info.fade-enter-active,
    .roulette-game .game-info.fade-leave-active {
        transition: opacity 0.3s;
    }

    .roulette-game .game-info.fade-enter-from,
    .roulette-game .game-info.fade-leave-to {
        opacity: 0;
    }

    .roulette-game .info-text {
        font-size: 13px;
        font-weight: 500;
        color: #767c8b;
    }

    .roulette-game .info-fairness {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-weight: 500;
        color: #ffffff;
    }

    .roulette-game .info-countdown {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-weight: 500;
        color: #ffffff;
    }

    .roulette-game .info-fairness span {
        margin-top: 3px;
        font-size: 22px;
        color: #fd3b31;
    }

    .roulette-game .countdown-text {
        display: flex;
        align-items: center;
        margin-top: 3px;
    }

    .roulette-game .countdown-text span {
        width: 13px;
        text-align: center;
        font-size: 22px;
        color: #fd3b31;
    }

    .roulette-game .countdown-text span:nth-child(3) {
        width: auto;
    }

    .roulette-game .game-selector {
        width: 2px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 5;
    }

    .roulette-game .game-selector:before {
        content: '';
        width: 3px;
        height: 100%;
        position: absolute;
        left: 0;
        background: #596076;
    }

    .roulette-game .game-selector:before {
        top: 0;
    }

    .roulette-game .game-selector:after {
        bottom: 0;
    }

    .roulette-game .game-selector svg {
        width: 13px;
        position: absolute;
        fill: #d3321d;
    }

    .roulette-game .game-selector svg:nth-child(1) {
        top: -39px;
        left: 6px;
        transform: rotate(-45deg);
    }

    .roulette-game .game-selector svg:nth-child(2) {
        top: -39px;
        right: 6px;
        transform: rotate(45deg);
    }

    .roulette-game .game-selector svg:nth-child(3) {
        bottom: -39px;
        left: 6px;
        transform: rotate(-135deg);
    }

    .roulette-game .game-selector svg:nth-child(4) {
        bottom: -39px;
        right: 6px;
        transform: rotate(135deg);
    }

    .roulette-game .game-reel {
        position: absolute;
        top: 50%;
        display: flex;
        align-items: center;
        transform: translate();
    }

    .roulette-game .reel-row {
        display: flex;
        align-items: center;
    }

    .roulette-game .reel-row img {
        width: 100px;
        height: 100px;
        margin-right: 25px;
    }
</style>