import { render, staticRenderFns } from "./WithdrawRust.vue?vue&type=template&id=2854bab0&scoped=true"
import script from "./WithdrawRust.vue?vue&type=script&lang=js"
export * from "./WithdrawRust.vue?vue&type=script&lang=js"
import style0 from "./WithdrawRust.vue?vue&type=style&index=0&id=2854bab0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2854bab0",
  null
  
)

export default component.exports