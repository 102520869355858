import sound from './sound';
import general from './general';
import blackjack from './blackjack';
import mines from './mines';
import towers from './towers';

export default {
    mixins: [
        sound,
        general,
        blackjack,
        mines,
        towers
    ]
};
