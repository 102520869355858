<template>
    <div class="battles-item-element" v-bind:class="[
        'element-' + (item !== null ? item.color : '')
    ]">
        <div v-if="item !== null" class="element-inner">
            <div class="inner-image">
                <img v-bind:src="item.item.image" alt="item-image" />
            </div>
            <div class="inner-name">{{item.item.name}}</div>
            <div class="inner-price">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="price-amount">
                    <span>{{ generalFormatAmount(item.item.priceFixed).split('.')[0] }}</span>.{{ generalFormatAmount(item.item.priceFixed).split('.')[1] }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';

    export default {
        name: 'BattlesItemElement',
        mixins: [
            mixins
        ],
        props: [
            'item'
        ]
    }
</script>

<style scoped>
    .battles-item-element {
        width: calc(25% - 3px);
        height: 218px;
        margin-bottom: 4px;
        margin-right: 4px;
        border-radius: 7px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
    }

    .battles-game.game-3 .battles-item-element {
        width: calc(33.33% - 2.66px);
    }

    .battles-game.game-4 .battles-item-element {
        width: calc(50% - 2px);
    }

    .battles-game.game-2 .battles-item-element:nth-child(4n) {
        margin-right: 0;
    }

    .battles-game.game-3 .battles-item-element:nth-child(3n) {
        margin-right: 0;
    }

    .battles-game.game-4 .battles-item-element:nth-child(2n) {
        margin-right: 0;
    }

    .battles-item-element .element-inner {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 33px 20px 16px 20px;
    }   

    .battles-item-element .inner-image {
        width: 108px;
        height: 108px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 42px;
        background: #1e222e;
        z-index: 0;
    }

    .battles-item-element .inner-image img {
        width: 84px;
        height: 84px;
    }

    .battles-item-element .inner-name {
        width: 100%;
        margin-top: 12px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        font-weight: 700;
        color: #596076;
    }

    .battles-item-element .inner-price {
        display: flex;
        align-items: center;
        margin-top: 8px;
    }

    .battles-item-element .inner-price img {
        width: 21px;
        margin-right: 10px;
    }

    .battles-item-element .price-amount {
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .battles-item-element .price-amount span {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    @media only screen and (max-width: 1100px) {

        .battles-item-element {
            width: calc(33.33% - 2.66px);
        }

        .battles-item-element:nth-child(4n) {
            margin-right: 4px;
        }

        .battles-item-element:nth-child(3n) {
            margin-right: 0;
        }

    }
</style>