<template>
    <div class="cases-item-element">
        <div class="element-percentage">
            {{item.tickets / 100000 * 100}}%
        </div>
        <div class="element-image">
            <img v-bind:src="item.item.image" alt="item-image" />
        </div>
        <div class="element-name">{{item.item.name}}</div>
        <div class="element-price">
            <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
            <div class="price-amount">
                <span>{{casesFormatValue(item.item.priceFixed).split('.')[0]}}</span>.{{casesFormatValue(item.item.priceFixed).split('.')[1]}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CasesItemElement',
        props: [
            'item'
        ],
        methods: {
            casesFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
        }
    }
</script>

<style scoped>
    .cases-item-element {
        width: calc(16.66% - 8.33px);
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        margin-right: 10px;
        padding: 33px 20px 16px 20px;
        border-radius: 18px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
    }

    .cases-item-element:nth-child(6n) {
        margin-right: 0;
    }

    .cases-item-element .element-percentage {
        position: absolute;
        top: 10px;
        right: 14px;
        font-size: 14px;
        font-weight: 700;
        color: #596076;
    }

    .cases-item-element .element-image {
        width: 108px;
        height: 108px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 42px;
        background: #1e222e;
        z-index: 0;
    }

    .cases-item-element .element-image img {
        width: 84px;
        height: 84px;
    }

    .cases-item-element .element-name {
        width: 100%;
        margin-top: 12px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        font-weight: 700;
        color: #596076;
    }

    .cases-item-element .element-price {
        display: flex;
        align-items: center;
        margin-top: 8px;
    }

    .cases-item-element .element-price img {
        width: 21px;
        margin-right: 10px;
    }

    .cases-item-element .price-amount {
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .cases-item-element .price-amount span {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    @media only screen and (max-width: 1000px) {

        .cases-item-element {
            width: calc(20% - 8px);
        }

        .cases-item-element:nth-child(6n) {
            margin-right: 10px;
        }

        .cases-item-element:nth-child(5n) {
            margin-right: 0;
        }

    }

    @media only screen and (max-width: 800px) {

        .cases-item-element {
            width: calc(25% - 7.5px);
        }

        .cases-item-element:nth-child(5n) {
            margin-right: 10px;
        }

        .cases-item-element:nth-child(4n) {
            margin-right: 0;
        }

    }

    @media only screen and (max-width: 650px) {

        .cases-item-element {
            width: calc(33.33% - 6.66px);
        }

        .cases-item-element:nth-child(4n) {
            margin-right: 10px;
        }

        .cases-item-element:nth-child(3n) {
            margin-right: 0;
        }

    }

    @media only screen and (max-width: 500px) {

        .cases-item-element {
            width: calc(50% - 5px);
        }

        .cases-item-element:nth-child(3n) {
            margin-right: 10px;
        }

        .cases-item-element:nth-child(2n) {
            margin-right: 0;
        }

    }
</style>