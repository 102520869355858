const state = {
    steamFilterSearch: '',
    steamFilterPrice: 'all',
    steamFilterSort: 'PRICE DESCENDING',
    steamDepositData: {
        items: null,
        transactions: null,
        selected: [],
        loading: false,
        loadedAt: null
    },
    steamWithdrawData: {
        items: null,
        transactions: null,
        selected: [],
        loading: false,
        loadedAt: null
    }
}

const getters = {
    steamFilterSearch: state => state.steamFilterSearch,
    steamFilterPrice: state => state.steamFilterPrice,
    steamFilterSort: state => state.steamFilterSort,
    steamDepositData: state => state.steamDepositData,
    steamWithdrawData: state => state.steamWithdrawData
}

const mutations = {
    steam_set_filter_search(state, value) {
        state.steamFilterSearch = value;
    },
    steam_set_filter_price(state, value) {
        state.steamFilterPrice = value;
    },
    steam_set_filter_sort(state, value) {
        state.steamFilterSort = value;
    },
    steam_set_deposit_data(state, data) {
        state.steamDepositData.items = data.items;
        state.steamDepositData.transactions = data.transactions;
        state.steamDepositData.loadedAt = new Date().getTime();
    },
    steam_remove_deposit_item(state, index) {
        state.steamDepositData.items.splice(index, 1);
    },
    steam_add_deposit_transaction(state, transaction) {
        state.steamDepositData.transactions.unshift(transaction);
    },
    steam_remove_deposit_transaction(state, index) {
        state.steamDepositData.transactions.splice(index, 1);
    },
    steam_set_deposit_data_loading(state, status) {
        state.steamDepositData.loading = status;
    },
    steam_add_deposit_selected(state, item) {
        state.steamDepositData.selected.push(item);
    },
    steam_remove_deposit_selected(state, index) {
        state.steamDepositData.selected.splice(index, 1);
    },
    steam_empty_deposit_selected(state) {
        state.steamDepositData.selected = [];
    },
    steam_set_withdraw_data(state, data) {
        state.steamWithdrawData.items = data.items;
        state.steamWithdrawData.loadedAt = new Date().getTime();
    },
    steam_update_withdraw_item(state, item) {
        state.steamWithdrawData.items[state.steamWithdrawData.items.findIndex((element) => element._id === item._id)].count = state.steamWithdrawData.items[state.steamWithdrawData.items.findIndex((element) => element._id === item._id)].count - item.count;
    },
    steam_remove_withdraw_item(state, item) {
        state.steamWithdrawData.items.splice(state.steamWithdrawData.items.findIndex((element) => element._id === item._id), 1);
    },
    steam_set_withdraw_data_loading(state, status) {
        state.steamWithdrawData.loading = status;
    },
    steam_add_withdraw_selected(state, item) {
        state.steamWithdrawData.selected.push(item);
    },
    steam_remove_withdraw_selected(state, index) {
        state.steamWithdrawData.selected.splice(index, 1);
    },
    steam_empty_withdraw_selected(state) {
        state.steamWithdrawData.selected = [];
    }
}

const actions = {
    steamSetFilterSearch({ commit }, value) {
        commit('steam_set_filter_search', value);
    },
    steamSetFilterPrice({ commit }, value) {
        commit('steam_set_filter_price', value);
    },
    steamSetFilterSort({ commit }, value) {
        commit('steam_set_filter_sort', value);
    },
    steamAddDepositSelected({ commit }, item) {
        commit('steam_add_deposit_selected', item);
    },
    steamRemoveDepositSelected({ getters, commit }, item) {
        const index = getters.steamDepositData.selected.findIndex((element) => element.name === item.name);
        if(index !== -1) { commit('steam_remove_deposit_selected', index); }
    },
    steamEmptyDepositSelected({ commit }) {
        commit('steam_empty_deposit_selected');
    },
    steamAddWithdrawSelected({ getters, commit }, item) {
        if(getters.steamWithdrawData.selected.filter((element) => element.name === item.name).length < item.count) {
            commit('steam_add_withdraw_selected', item);
        }
    },
    steamRemoveWithdrawSelected({ getters, commit }, item) {
        const index = getters.steamWithdrawData.selected.findIndex((element) => element.name === item.name);
        if(index !== -1) { commit('steam_remove_withdraw_selected', index); }
    },
    steamEmptyWithdrawSelected({ commit }) {
        commit('steam_empty_withdraw_selected');
    },
    steamSocketTransaction({ getters, commit, dispatch }, data) {
        if(data.transaction.type === 'deposit') {
            if(data.transaction.state === 'completed') {
                for(const item of data.transaction.data.items) {
                    const index = getters.steamDepositData.items.findIndex((element) => element.assetid === item.assetid);
                    if(index !== -1) { commit('steam_remove_deposit_item', index); }
                }

                getters.soundCash.volume = getters.soundVolume;
                getters.soundCash.play();

                dispatch('notificationShow', { type: 'success', message: 'Your RUST deposit has been confirmed. We have added ' + parseFloat(data.transaction.amount / 1000).toFixed(2) + ' to your balance.' });

                const index = getters.steamDepositData.transactions.findIndex((element) => element._id === data.transaction._id);
                if(index !== -1) { commit('steam_remove_deposit_transaction', index); }
            } else if(data.transaction.state === 'sent') {
                dispatch('notificationShow', { type: 'transaction', message: 'Your RUST deposit trade is ready. <span style="color: #fd3b31">CODE: '+data.transaction.token+'</span>', transaction: data.transaction });
            } else {
                if(data.transaction.state === 'failed') {
                    dispatch('notificationShow', { type: 'error', message: 'Your RUST deposit has been failed. We was not able to send you a trade offer.' });
                } else {
                    dispatch('notificationShow', { type: 'error', message: 'Your RUST deposit has been canceled.' });
                }

                const index = getters.steamDepositData.transactions.findIndex((element) => element._id === data.transaction._id);
                if(index !== -1) { commit('steam_remove_deposit_transaction', index); }
            }
        } else if(data.transaction.type === 'withdraw') {
            if(data.transaction.state === 'completed') {
                dispatch('notificationShow', { type: 'success', message: 'Your RUST withdrawal has been confirmed.' });
            } else if(data.transaction.state === 'sent') {
                dispatch('notificationShow', { type: 'transaction', message: 'Your RUST withdrawal trade is ready. <span style="color: #fd3b31">CODE: '+data.transaction.token+'</span>', transaction: data.transaction });
            } else if(data.transaction.state === 'failed') {
                dispatch('notificationShow', { type: 'error', message: 'Your RUST withdrawal has been failed. We was not able to send you a trade offer.' });
            } else {
                dispatch('notificationShow', { type: 'error', message: 'Your RUST withdrawal has been canceled.' });
            }
        }
    },
    steamSocketWithdrawItem({ getters, commit }, data) {
        const index = getters.steamWithdrawData.items.findIndex((element) => element._id === data.item._id);
        if((getters.steamWithdrawData.items[index].count - data.item.count) >= 1) {
            commit('steam_update_withdraw_item', data.item);
        } else {
            commit('steam_remove_withdraw_item', data.item);
        }
    },
    steamGetDepositDataSocket({ getters, commit, dispatch }, data) {
        if(getters.steamDepositData.loading === true) { return; }
        commit('steam_set_deposit_data_loading', true);

        getters.socketClient.emit('cashier:steam:getDepositData', data, (res) => {
            if(res.success === true) {
                commit('steam_set_deposit_data', res);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('steam_set_deposit_data_loading', false);
        });
    },
    steamGetWithdrawDataSocket({ getters, commit, dispatch }, data) {
        if(getters.steamWithdrawData.loading === true) { return; }
        commit('steam_set_withdraw_data_loading', true);

        getters.socketClient.emit('cashier:steam:getWithdrawData', data, (res) => {
            if(res.success === true) {
                commit('steam_set_withdraw_data', res);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('steam_set_withdraw_data_loading', false);
        });
    },
    steamSendDepositSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'SteamDeposit');

        getters.socketClient.emit('cashier:steam:sendDeposit', data, (res) => {
            if(res.success === true) {
                commit('steam_add_deposit_transaction', res.transaction);
                commit('steam_empty_deposit_selected');
                dispatch('notificationShow', { type: 'success', message: 'Waiting for your requested RUST deposit trade offer.' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    steamSendWithdrawSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'SteamWithdraw');

        getters.socketClient.emit('cashier:steam:sendWithdraw', data, (res) => {
            if(res.success === true) {
                commit('auth_update_user', res.user);
                commit('steam_empty_withdraw_selected');
                dispatch('notificationShow', { type: 'success', message: 'Waiting for your requested RUST withdrawal trade offer.' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    }
}

const steam = {
    state,
    mutations,
    actions,
    getters
}

export default steam;
