<template>
    <div class="bets-element">
        <div class="element-game">
            <IconBlackjack v-if="bet.method === 'blackjack'" />
            <IconMines v-else-if="bet.method === 'mines'" />
            <IconTowers v-else-if="bet.method === 'towers'" />
            <IconPlinko v-else-if="bet.method === 'plinko'" />
            <IconUpgrader v-else-if="bet.method === 'upgrader'" />
            <IconRoulette v-else-if="bet.method === 'roulette'" />
            <IconCases v-else-if="bet.method === 'cases'" />
            <IconBattles v-else-if="bet.method === 'battles'" />

            {{bet.method.toUpperCase()}}
        </div>
        <div class="element-user">
            <AvatarImage v-bind:image="bet.user === null ? null : bet.user.avatar" />
            <div v-if="bet.user !== null" class="user-info">
                <div class="info-rank" v-bind:class="[ 'rank-' + bet.user.rank ]">
                    <IconStar v-if="['admin', 'mod'].includes(bet.user.rank) !== true" />
                    {{ ['admin', 'mod'].includes(bet.user.rank) === true ? bet.user.rank.toUpperCase() : bet.user.level }}
                </div>
                <div v-html="bet.user.username" class="info-username"></div>
            </div>
            <div v-else class="user-hidden">HIDDEN</div>
        </div>
        <div class="element-time">
            {{ new Date(bet.updatedAt).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) }}
            <span class="time-text">TIME</span>
        </div>
        <div class="element-multiplier">
            x{{parseFloat(Math.floor((bet.payout / betsGetBetAmount) * 100) / 100).toFixed(2)}}
            <span class="multiplier-text">MULTIPLIER</span>
        </div>
        <div class="element-bet">
            <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
            <div class="bet-amount">
                <span class="amount-value">{{generalFormatAmount(betsGetBetAmount).split('.')[0]}}</span>.{{generalFormatAmount(betsGetBetAmount).split('.')[1]}} <span class="amount-text">BET</span>
            </div>
        </div>
        <div class="element-payout">
            <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
            <div class="payout-amount">
                <span class="amount-value">{{generalFormatAmount(bet.payout).split('.')[0]}}</span>.{{generalFormatAmount(bet.payout).split('.')[1]}}
                <span v-if="bet.payout > 0" class="amount-text text-won">WON</span>
                <span v-else class="amount-text text-lost">LOST</span>
            </div>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';
    import IconBlackjack from '@/components/icons/IconBlackjack';
    import IconMines from '@/components/icons/IconMines';
    import IconTowers from '@/components/icons/IconTowers';
    import IconPlinko from '@/components/icons/IconPlinko';
    import IconUpgrader from '@/components/icons/IconUpgrader';
    import IconRoulette from '@/components/icons/IconRoulette';
    import IconCases from '@/components/icons/IconCases';
    import IconBattles from '@/components/icons/IconBattles';
    import IconStar from '@/components/icons/IconStar';
    import AvatarImage from '@/components/AvatarImage';

    export default {
        name: 'BetsElement',
        components: {
            IconBlackjack,
            IconMines,
            IconTowers,
            IconPlinko,
            IconUpgrader,
            IconRoulette,
            IconCases,
            IconBattles,
            IconStar,
            AvatarImage
        },
        mixins: [
            mixins
        ],
        props: [
            'bet'
        ],
        computed: {
            betsGetBetAmount() {
                let amount = this.bet.amount;

                if(this.bet.method === 'blackjack') {
                    amount = this.bet.actions.includes('split') === true || this.bet.actions.includes('double') === true ? Math.floor(amount * 2) : amount;
                    amount = this.bet.actions.includes('insurance') ? amount + Math.floor(this.bet.amount * 0.5) : amount;
                }

                return amount;
            }
        }
    }
</script>

<style scoped>
    .bets-element {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
        padding-bottom: 18px;
        border-bottom: 2px solid #191d29;
    }

    .bets-element:first-of-type {
        margin-top: 0;
    }

    .bets-element .element-game {
        width: 15%;
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 700;
        color: #616d8b;
    }

    .bets-element .element-game svg {
        width: 23px;
        height: 23px;
        margin-right: 12px;
        fill: #616d8b;
    }

    .bets-element .element-user {
        width: 25%;
        display: flex;
        align-items: center;
    }

    .bets-element .element-user .avatar-image {
        width: 24px;
        height:  24px;
        margin-right: 6px;
        border-radius: 5px;
    }

    .bets-element .user-info {
        display: flex;
        align-items: center;
    }

    .bets-element .info-rank {
        height: 19px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        padding: 1px 5px 0 5px;
        border-radius: 3px;
        font-size: 10px;
        font-weight: 700;
        color: #ffffff;
        background: #a1aeb5;
    }

    .bets-element .info-rank.rank-mod {
        background: #00c74d;
    }

    .bets-element .info-rank.rank-admin {
        background: #fd3b31;
    }

    .bets-element .info-rank svg {
        width: 11px;
        margin-right: 5px;
        margin-bottom: 1px;
        fill: #ffffff;
    }

    .bets-element .info-username {
        max-width: 120px;
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .bets-element .user-hidden {
        font-style: italic;
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .bets-element .element-time {
        width: 15%;
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .bets-element .element-time span.time-text {
        margin-left: 10px;
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .bets-element .element-multiplier {
        width: 15%;
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .bets-element .element-multiplier span.multiplier-text {
        margin-left: 10px;
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .bets-element .element-bet {
        width: 15%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .bets-element .element-bet img {
        width: 21px;
        margin-right: 10px;
    }

    .bets-element .bet-amount {
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .bets-element .bet-amount span.amount-value {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .bets-element .bet-amount span.amount-text {
        margin-left: 10px;
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .bets-element .element-payout {
        width: 15%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .bets-element .element-payout img {
        width: 21px;
        margin-right: 10px;
    }

    .bets-element .payout-amount {
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .bets-element .payout-amount span.amount-value {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .bets-element .payout-amount span.amount-text {
        margin-left: 10px;
        font-size: 12px;
        font-weight: 700;
    }

    .bets-element .payout-amount span.amount-text.text-won {
        color: #00c74d;
    }

    .bets-element .payout-amount span.amount-text.text-lost {
        color: #fd3b31;
    }

    @media only screen and (max-width: 1200px) {

        .bets-element {
            width: 1160px;
        }

    }
</style>
