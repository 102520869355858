<template>
    <div class="deposit-rust">
        <div class="rust-header">
            <div class="header-search">
                <SteamFilterSearch />
                <button v-on:click="depositReloadButton()" class="button-reload" v-bind:disabled="this.steamDepositData.loading === true">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M449.9 39.96l-48.5 48.53C362.5 53.19 311.4 32 256 32C161.5 32 78.59 92.34 49.58 182.2c-5.438 16.81 3.797 34.88 20.61 40.28c16.97 5.5 34.86-3.812 40.3-20.59C130.9 138.5 189.4 96 256 96c37.96 0 73 14.18 100.2 37.8L311.1 178C295.1 194.8 306.8 223.4 330.4 224h146.9C487.7 223.7 496 215.3 496 204.9V59.04C496 34.99 466.9 22.95 449.9 39.96zM441.8 289.6c-16.94-5.438-34.88 3.812-40.3 20.59C381.1 373.5 322.6 416 256 416c-37.96 0-73-14.18-100.2-37.8L200 334C216.9 317.2 205.2 288.6 181.6 288H34.66C24.32 288.3 16 296.7 16 307.1v145.9c0 24.04 29.07 36.08 46.07 19.07l48.5-48.53C149.5 458.8 200.6 480 255.1 480c94.45 0 177.4-60.34 206.4-150.2C467.9 313 458.6 294.1 441.8 289.6z"/>
                    </svg>
                </button>
            </div>
            <div class="header-filter">
                <SteamFilterPrice />
                <SteamFilterSort />
            </div>
        </div>
        <div class="rust-content">
            <transition name="fade" mode="out-in">
                <div v-if="steamDepositData.loading === true" class="content-loading" key="loading">
                    <LoadingAnimation />
                </div>
                <div v-else-if="steamDepositData.items !== null && depositGetItems.length > 0" class="content-items" key="data">

                    <SteamItemElement v-for="item of depositGetItems" v-bind:key="item.assetid" v-bind:item="item" />

                </div>
                <div v-else class="content-empty" key="empty">NO ITEMS FOUND</div>
            </transition>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import SteamFilterSearch from '@/components/steam/SteamFilterSearch';
    import SteamFilterPrice from '@/components/steam/SteamFilterPrice';
    import SteamFilterSort from '@/components/steam/SteamFilterSort';
    import SteamItemElement from '@/components/steam/SteamItemElement';

    export default {
        name: 'DepositRust',
        components: {
            LoadingAnimation,
            SteamFilterSearch,
            SteamFilterPrice,
            SteamFilterSort,
            SteamItemElement
        },
        methods: {
            ...mapActions([
                'notificationShow',
                'steamEmptyDepositSelected', 
                'steamGetDepositDataSocket'
            ]),
            depositReloadButton() {
                if((new Date().getTime() - this.steamDepositData.loadedAt) < 30 * 1000) {
                    this.notificationShow({ type: 'error', message: 'You need to wait 30 seconds before you can reload.' });
                    return;
                }

                this.steamEmptyDepositSelected();
                this.steamGetDepositDataSocket();
            }
        },
        computed: {
            ...mapGetters([
                'steamDepositData', 
                'steamFilterSearch', 
                'steamFilterPrice', 
                'steamFilterSort'
            ]),
            depositGetItems() {
                let items = [];

                for(const item of this.steamDepositData.items) {
                    const index = items.findIndex((element) => element.name === item.name);

                    if(index !== -1) {
                        items[index].count = items[index].count + item.count;
                        items[index].positions.push({ assetid: item.assetid, count: item.count });
                    } else {
                        items.push({
                            name: item.name,
                            image: item.image,
                            amount: item.amount,
                            count: item.count,
                            accepted: item.accepted,
                            positions: [
                                {
                                    assetid: item.assetid,
                                    count: item.count
                                }
                            ]
                        });
                    }
                }

                if(this.steamDepositData.transactions !== null) {
                    for(const transaction of this.steamDepositData.transactions) {
                        for(const transactionItem of transaction.data.items) {
                            const indexItem = items.findIndex((element) => element.name === transactionItem.name);

                            if(indexItem !== -1) {
                                const indexPos = items[indexItem].positions.findIndex((element) => element.assetid === transactionItem.assetid);

                                if(indexPos !== -1) {
                                    items[indexItem].count = items[indexItem].count - transactionItem.count;
                                    items[indexItem].positions[indexPos].count = items[indexItem].positions[indexPos].count - transactionItem.count;

                                    if(items[indexItem].amount <= 0) { items.splice(indexItem, 1); }
                                }
                            }
                        }
                    }
                }

                if(this.steamFilterSort == 'PRICE DESCENDING') {
                    items.sort((a, b) => { return b.amount - a.amount; });
                } else {
                    items.sort((a, b) => { return a.amount - b.amount; });
                }

                if(this.steamFilterPrice == '0.00 - 5.00') {
                    items = items.filter((element) => element.amount <= 5000);
                } else if(this.steamFilterPrice == '5.00 - 25.00') {
                    items = items.filter((element) => (element.amount > 5000 && element.amount <= 25000));
                } else if(this.steamFilterPrice == '25.00 - 100.00') {
                    items = items.filter((element) => (element.amount > 25000 && element.amount <= 100000));
                } else if(this.steamFilterPrice == '100.00+') {
                    items = items.filter((element) => element.amount > 100000);
                }

                items = items.filter((element) => element.name.toLowerCase().includes(this.steamFilterSearch.toLowerCase().trim()) === true);

                return items;
            }
        },
        created() {
            this.steamEmptyDepositSelected();

            if((this.steamDepositData.items === null || (new Date().getTime() - this.steamDepositData.loadedAt) >= 30 * 1000) && this.steamDepositData.loading === false) {
                this.steamGetDepositDataSocket();
            }
        }
    }
</script>

<style scoped>
    .deposit-rust {
        width: 100%;
        margin-top: 35px;
    }

    .deposit-rust .rust-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .deposit-rust .header-search {
        display: flex;
        align-items: center;
    }

    .deposit-rust button.button-reload {
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 12px;
        border-radius: 8px;
        background: #212732;
        border-bottom: 2px solid #191e27;
    }

    .deposit-rust button.button-reload svg {
        width: 17px;
        fill: #767c8b;
        transition: all 0.3s ease;
    }

    .deposit-rust button.button-reload:disabled svg {
        animation: rotate_animation 1s ease infinite;
    }

    .deposit-rust button.button-reload:not([disabled]):hover svg {
        fill: #ffffff;
    }

    .deposit-rust .header-filter {
        display: flex;
        align-items: center;
    }

    .deposit-rust .rust-content {
        width: 100%;
        margin-top: 26px;
    }

    .deposit-rust .content-loading {
        width: 100%;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .deposit-rust .content-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .deposit-rust .content-loading.fade-leave-to {
        opacity: 0;
    }

    .deposit-rust .content-items {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .deposit-rust .content-empty {
        width: 100%;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #626c7e;
    }

    .deposit-rust .content-items.fade-enter-active,
    .deposit-rust .content-empty.fade-enter-active {
        transition: opacity 0.5s;
    }

    .deposit-rust .content-items.fade-enter-from,
    .deposit-rust .content-empty.fade-enter-from {
        opacity: 0;
    }

    @keyframes rotate_animation {
        0% { transform: rotate(0deg); }
        50% { transform: rotate(180deg); }
        100% { transform: rotate(360deg); }
    }

    @media only screen and (max-width: 1350px) {

        .deposit-rust .rust-header {
            flex-direction: column;
            align-items: flex-start;
        }

        .deposit-rust .header-search {
            width: 100%;
        }

        .deposit-rust .header-search input {
            width: 100%;
        }

        .deposit-rust .header-filter {
            margin-top: 26px;
        }

    }

    @media only screen and (max-width: 475px) {

        .deposit-rust .header-filter {
            flex-direction: column;
            align-items: flex-start;
        }

    }
</style>
