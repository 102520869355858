<template>
    <div class="steam-filter-sort" v-bind:class="{ 'sort-ascending': steamFilterSort === 'PRICE ASCENDING' }">
        <button class="sort-toggle" v-on:click="steamSetFilterSort(steamFilterSort === 'PRICE DESCENDING' ? 'PRICE ASCENDING' : 'PRICE DESCENDING')">
            {{steamFilterSort}}
            <IconCaretDown />
        </button>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import IconCaretDown from '@/components/icons/IconCaretDown';

    export default {
        name: 'SteamFilterSort',
        components: {
            IconCaretDown
        },
        methods: {
            ...mapActions([
                'steamSetFilterSort'
            ])
        },
        computed: {
            ...mapGetters([
                'steamFilterSort'
            ])
        }
    }
</script>

<style scoped>
    .steam-filter-sort {
         position: relative;
    }

    .steam-filter-sort button.sort-toggle {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        color: #fd3b31;
    }

    .steam-filter-sort button.sort-toggle svg {
        height: 9px;
        margin-left: 6px;
        fill: #fd3b31;
        transition: all 0.3s ease;
    }

    .steam-filter-sort.sort-ascending button.sort-toggle svg {
        transform: rotate(180deg);
    }

    @media only screen and (max-width: 475px) {

        .steam-filter-sort {
            margin-top: 20px;
        }

    }
</style>
