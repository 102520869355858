<template>
    <div class="withdraw-rust">
        <div class="rust-header">
            <div class="header-search">
                <SteamFilterSearch />
            </div>
            <div class="header-filter">
                <SteamFilterPrice />
                <SteamFilterSort />
            </div>
        </div>
        <div class="rust-content">
            <transition name="fade" mode="out-in">
                <div v-if="steamWithdrawData.loading === true" class="content-loading" key="loading">
                    <LoadingAnimation />
                </div>
                <div v-else-if="steamWithdrawData.items !== null && steamWithdrawData.items.length > 0" class="content-items" key="data">

                    <SteamItemElement v-for="item of withdrawGetItems" v-bind:key="item.name" v-bind:item="item" />

                </div>
                <div v-else class="content-empty" key="empty">NO ITEMS FOUND</div>
            </transition>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import SteamFilterSearch from '@/components/steam/SteamFilterSearch';
    import SteamFilterPrice from '@/components/steam/SteamFilterPrice';
    import SteamFilterSort from '@/components/steam/SteamFilterSort';
    import SteamItemElement from '@/components/steam/SteamItemElement';

    export default {
        name: 'WithdrawRust',
        components: {
            LoadingAnimation,
            SteamFilterSearch,
            SteamFilterPrice,
            SteamFilterSort,
            SteamItemElement
        },
        methods: {
            ...mapActions([
                'steamEmptyWithdrawSelected', 
                'steamGetWithdrawDataSocket'
            ])
        },
        computed: {
            ...mapGetters([
                'steamWithdrawData', 
                'steamFilterSearch', 
                'steamFilterPrice', 
                'steamFilterSort'
            ]),
            withdrawGetItems() {
                let items = [];

                for(const item of this.steamWithdrawData.items) {
                    const index = items.findIndex((element) => element.name === item.name);

                    if(index !== -1) {
                        items[index].count = items[index].count + item.count;
                        items[index].positions.push({ _id: item._id, count: item.count });
                    } else {
                        items.push({
                            name: item.name,
                            image: item.image,
                            amount: item.amount,
                            count: item.count,
                            positions: [
                                {
                                    _id: item._id,
                                    count: item.count
                                }
                            ]
                        });
                    }
                }

                if(this.steamFilterSort == 'PRICE DESCENDING') {
                    items.sort((a, b) => { return b.amount - a.amount; });
                } else {
                    items.sort((a, b) => { return a.amount - b.amount; });
                }

                if(this.steamFilterPrice == '0.00 - 5.00') {
                    items = items.filter((element) => element.amount <= 5000);
                } else if(this.steamFilterPrice == '5.00 - 25.00') {
                    items = items.filter((element) => (element.amount > 5000 && element.amount <= 25000));
                } else if(this.steamFilterPrice == '25.00 - 100.00') {
                    items = items.filter((element) => (element.amount > 25000 && element.amount <= 100000));
                } else if(this.steamFilterPrice == '100.00+') {
                    items = items.filter((element) => element.amount > 100000);
                }

                items = items.filter((element) => element.name.toLowerCase().includes(this.steamFilterSearch.toLowerCase().trim()) === true);

                return items;
            }
        },
        created() {
            this.steamEmptyWithdrawSelected();

            if((this.steamWithdrawData.items === null || (new Date().getTime() - this.steamWithdrawData.loadedAt) >= 30 * 1000) && this.steamWithdrawData.loading === false) {
                this.steamGetWithdrawDataSocket();
            }
        }
    }
</script>

<style scoped>
    .withdraw-rust {
        width: 100%;
        height: calc(100% - 78px);
        margin-top: 35px;
    }

    .withdraw-rust .rust-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .withdraw-rust .header-filter {
        display: flex;
        align-items: center;
    }

    .withdraw-rust .rust-content {
        width: 100%;
        margin-top: 26px;
    }

    .withdraw-rust .content-loading {
        width: 100%;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .withdraw-rust .content-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .withdraw-rust .content-loading.fade-leave-to {
        opacity: 0;
    }

    .withdraw-rust .content-items {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .withdraw-rust .content-empty {
        width: 100%;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #626c7e;
    }

    .withdraw-rust .content-items.fade-enter-active,
    .withdraw-rust .content-empty.fade-enter-active {
        transition: opacity 0.5s;
    }

    .withdraw-rust .content-items.fade-enter-from,
    .withdraw-rust .content-empty.fade-enter-from {
        opacity: 0;
    }

    @media only screen and (max-width: 1350px) {

        .withdraw-rust .rust-header {
            flex-direction: column;
            align-items: flex-start;
        }

        .withdraw-rust .header-search {
            width: 100%;
        }

        .withdraw-rust .header-search input {
            width: 100%;
        }

        .withdraw-rust .header-filter {
            margin-top: 26px;
        }

    }

    @media only screen and (max-width: 475px) {

        .withdraw-rust .header-filter {
            flex-direction: column;
            align-items: flex-start;
        }

    }
</style>
