<template>
    <div class="profile-transactions-element">
        <div class="element-section section-type">
            <div class="section-title">TYPE</div>
            <div class="section-content">
                {{profileGetMethod}}
            </div>
        </div>
        <div class="element-section section-amount">
            <div class="section-title">AMOUNT</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="amount-value">
                    <span>{{generalFormatAmount(transaction.amount).split('.')[0]}}</span>.{{generalFormatAmount(transaction.amount).split('.')[1]}}
                </div>
            </div>
        </div>
        <div class="element-section section-date">
            <div class="section-title">DATE</div>
            <div class="section-content">
                {{ new Date(transaction.createdAt).toLocaleString('en-US', { hour12: true }) }}
            </div>
        </div>
        <div class="element-section section-transaction">
            <div class="section-title">TRANSACTION</div>
            <div class="section-content">
                <div v-if="transaction.method === 'steam'" class="transaction-steam">
                    <img v-for="item in transaction.data.items.slice(0, 4)" v-bind:key="item.assetid" v-bind:src="'https://steamcommunity-a.akamaihd.net/economy/image/'+item.image" />
                    <span v-if="transaction.data.items.length > 4">+{{transaction.data.items.length - 4}}</span>
                </div>
                <div v-else-if="transaction.method === 'crypto'" class="transaction-crypto">
                    <a v-bind:href="profileGetLinkCrypto" target="_blank" class="link-explorer">{{transaction.data.transaction}}</a>
                </div>
                <div v-else class="transaction-gift">

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';

    export default {
        name: 'ProfileTransactionsElement',
        mixins: [
            mixins
        ],
        props: [
            'transaction'
        ],
        computed: {
            profileGetMethod() {
                let method = this.transaction.method.toUpperCase() + ' ' + this.transaction.type.toUpperCase();

                if(this.transaction.method === 'crypto') {
                    method = this.transaction.data.currency.toUpperCase() + ' ' + this.transaction.type.toUpperCase();
                }

                return method;
            },
            profileGetLinkCrypto() {
                let explorer = 'https://blockchair.com/de/bitcoin/transaction/';

                if(this.transaction.data.currency === 'eth') {
                    explorer = 'https://etherscan.io/tx/';
                } else if(this.transaction.data.currency === 'ltc') {
                    explorer = 'https://blockchair.com/de/litecoin/transaction/';
                }

                return explorer + this.transaction.data.transaction;
            }
        }
    }
</script>

<style scoped>
    .profile-transactions-element {
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
    }

    .profile-transactions-element:nth-child(odd) {
        border-radius: 8px;
        background: #1d212d;
    }

    .profile-element-element .element-section {
        display: flex;
        flex-direction: column;
    }

    .profile-transactions-element .element-section.section-type,
    .profile-transactions-element .element-section.section-date {
        width: 25%;
    }

    .profile-transactions-element .element-section.section-amount {
        width: 15%;
    }

    .profile-transactions-element .element-section.section-transaction {
        width: 15%;
    }

    .profile-transactions-element .section-title {
        display: none;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .profile-transactions-element .section-content {
        display: flex;
        align-items: center;
    }

    .profile-transactions-element .element-section.section-type .section-content,
    .profile-transactions-element .element-section.section-date .section-content {
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .profile-transactions-element .element-section.section-amount .section-content img {
        width: 21px;
        margin-right: 10px;
    }

    .profile-transactions-element .amount-value {
        font-size: 12px;
        font-weight: 700;
        color: #00c74d;
    }

    .profile-transactions-element .amount-value span {
        font-size: 15px;
        font-weight: 700;
    }

    .profile-transactions-element .transaction-steam {
        display: flex;
        align-items: center;
    }

    .profile-transactions-element .transaction-steam img {
        width: 30px;
        margin-right: 5px;
    }

    .profile-transactions-element .transaction-steam img:last-of-type {
        margin-right: 0;
    }

    .profile-transactions-element .transaction-steam span {
        margin-left: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .profile-transactions-element .transaction-crypto {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .profile-transactions-element .transaction-crypto a.link-explorer {
        display: block;
        font-size: 15px;
        font-weight: 700;
        color: #fd3b31;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media only screen and (max-width: 1300px) {

        .profile-transactions-element {
            height: auto;
            flex-direction: column;
            align-items: flex-start;
            padding: 10px 20px;
        }

        .profile-transactions-element .element-section {
            width: 100%!important;
            margin-top: 10px;
        }

        .profile-transactions-element .element-section.section-type {
            margin-top: 0;
        }

        .profile-transactions-element .element-section.section-actions {
            align-items: flex-start;
        }

        .profile-transactions-element .section-title {
            display: block;
        }

        .profile-transactions-element .section-content {
            margin-top: 5px;
        }

    }
</style>
