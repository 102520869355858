<template>
    <div class="modal-fair-info">
        <div class="info-title">
            FAIRNESS
        </div>
        <div class="info-close">
            <button v-on:click="modalsSetShow(null)">
                <svg width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0005 1.22222L9.77827 0L5.50019 4.27808L1.22222 0.000111474L0 1.22233L4.27797 5.5003L0.000501987 9.77776L1.22272 11L5.50019 6.72252L9.77776 11.0001L11 9.77788L6.72241 5.5003L11.0005 1.22222Z" />
                </svg>
                CLOSE
            </button>
        </div>
        <div class="info-content">
            <div class="content-element">
                <div class="element-title">GAME ID</div>
                <div class="element-value">{{ modalsGetGameId }}</div>
            </div>
            <div class="content-element">
                <div class="element-title">
                    SERVER SEED
                    <span v-if="modalsGetSeedServer === null">(HASHED)</span>
                </div>
                <div class="element-value">{{ modalsGetSeedServer === null ? modalsGetHash : modalsGetSeedServer }}</div>
            </div>
            <div v-bind:class="['content-group', {
                'group-full': modalsGetNonce === null && modalsGetSeedClient === null
            }]">
                <div v-if="modalsGetSeedPublic !== null" class="group-element">
                    <div class="element-title">PUBLIC SEED</div>
                    <div class="element-value">{{ modalsGetSeedPublic }}</div>
                </div>
                <div v-if="modalsGetNonce !== null" class="group-element">
                    <div class="element-title">NONCE</div>
                    <div class="element-value">{{ modalsGetNonce }}</div>
                </div>
                <div v-if="modalsGetSeedClient !== null" class="group-element">
                    <div class="element-title">CLIENT SEED</div>
                    <div class="element-value">{{ modalsGetSeedClient }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'ModalFairInfo',
        methods: {
            ...mapActions([
                'modalsSetShow'
            ])
        },
        computed: {
            ...mapGetters([
                'modalsData'
            ]),
            modalsGetGameId() {
                return ['battles', 'roulette'].includes(this.modalsData.method) === true ? this.modalsData.game._id : this.modalsData._id;
            },
            modalsGetSeedServer() {
                let seed = null;

                if(['battles', 'roulette'].includes(this.modalsData.method) === true) { 
                    if(this.modalsData.game.fair.seedServer !== undefined) { seed = this.modalsData.game.fair.seedServer; }
                } else if(this.modalsData.fair.seed.serverSeed !== undefined) { 
                    seed = this.modalsData.fair.seed.serverSeed; 
                }

                return seed;
            },
            modalsGetHash() {
                return ['battles', 'roulette'].includes(this.modalsData.method) === true ? this.modalsData.game.fair.hash : this.modalsData.fair.seed.hash;
            },
            modalsGetSeedPublic() {
                let seed = null;

                if(['battles', 'roulette'].includes(this.modalsData.method) === true) { 
                    if(this.modalsData.game.fair.seedPublic !== undefined) { seed = this.modalsData.game.fair.seedPublic; } 
                } else if(this.modalsData.fair.publicSeed !== undefined) { 
                    seed = this.modalsData.fair.publicSeed; 
                }

                return seed;
            },
            modalsGetSeedClient() {
                let seed = null;

                if(['battles', 'roulette'].includes(this.modalsData.method) !== true && this.modalsData.fair.clientSeed !== undefined) { 
                    seed = this.modalsData.fair.clientSeed;
                }

                return seed;
            },
            modalsGetNonce() {
                let nonce = null;

                if(['battles', 'roulette'].includes(this.modalsData.method) !== true && this.modalsData.fair.nonce !== undefined) { 
                    nonce = this.modalsData.fair.nonce;
                }

                return nonce;
            }
        }
    }
</script>

<style scoped>
    .modal-fair-info {
        width: 720px;
        position: relative;
        display: flex;
        justify-content: center;
        padding: 60px 0 10px 0;
        border-radius: 8px;
        background: #222635;
    }

    .modal-fair-info .info-title {
        height: 60px;
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        left: 0;
        padding-left: 22px;
        font-size: 18px;
        font-weight: 700;
        color: #596076;
    }

    .modal-fair-info .info-close {
        position: absolute;
        top: 17px;
        right: 12px;
    }

    .modal-fair-info .info-close button {
        height: 27px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
        background: #191e27;
    }

    .modal-fair-info .info-close button:hover {
        color: #ffffff;
    }

    .modal-fair-info .info-close button svg {
        margin-right: 8px;
        fill: #767c8b;
        transition: all 0.3s ease;
    }

    .modal-fair-info .info-close button:hover svg {
        fill: #ffffff;
    }

    .modal-fair-info .info-content {
        width: 100%;
        padding: 15px 12px;
        background: #1c202c;
    }

    .modal-fair-info .content-group,
    .modal-fair-info .content-element {
        width: 100%;
        margin-top: 12px;
    }

    .modal-fair-info .content-group {
        display: flex;
        justify-content: space-between;
    }

    .modal-fair-info .content-group:first-child,
    .modal-fair-info .content-element:first-child {
        margin-top: 0;
    }

    .modal-fair-info .group-element {
        width: calc(50% - 6px);
    }

    .modal-fair-info .content-group.group-full .group-element {
        width: 100%;
    }

    .modal-fair-info .element-title {
        padding: 0 5px;
        font-size: 14px;
        font-weight: 600;
        color: #596076;
    }

    .modal-fair-info .element-title span {
        color: #fd3b31;
    }

    .modal-fair-info .element-value {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        margin-top: 5px;
        padding: 0 12px;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 600;
        color: #767c8b;
        background: #12161d;
        overflow: hidden;
    }

    @media only screen and (max-width: 740px) {

        .modal-fair-info {
            width: 100%;
        }

    }

</style>