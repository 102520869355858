<template>
    <div class="modal-redeem">
        <div class="redeem-close">
            <button v-on:click="modalsSetShow(null)">
                <svg width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0005 1.22222L9.77827 0L5.50019 4.27808L1.22222 0.000111474L0 1.22233L4.27797 5.5003L0.000501987 9.77776L1.22272 11L5.50019 6.72252L9.77776 11.0001L11 9.77788L6.72241 5.5003L11.0005 1.22222Z" />
                </svg>
                CLOSE
            </button>
        </div> 
        <div class="redeem-content">
            <div class="content-title">REDEEM CODE</div>
            <div class="content-info">
                Use Code <span class="info-code">“ROCKET”</span> for a free
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="info-amount">
                    <span>0</span>.50
                </div>
            </div>
            <div class="content-input">
                <input v-model="affiliatesCode" type="text" placeholder="Enter code..." />
            </div>
            <div class="content-button">
                <button v-on:click="affiliatesRedeemButton()" class="button-redeem">REDEEM CODE</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'ModalRedeem',
        data() {
            return {
                affiliatesCode: ''
            }
        },
        methods: {
            ...mapActions([
                'modalsSetShow', 
                'modalsSetData', 
                'notificationShow'
            ]),
            affiliatesRedeemButton() {
                if(this.affiliatesCode === null || this.affiliatesCode.trim() === '' || this.affiliatesCode.trim().length <= 3) {
                    this.notificationShow({ type: 'error', message: 'Your entered affiliate code is invalid.' });
                    return;
                }

                if(this.generalSettings.general.redeem.enabled === false && this.authUser.user.rank !== 'admin') {
                    this.notificationShow({ type: 'error', message: 'You can not redeem a affiliate code because the action is not available at the moment.' });
                    return;
                }

                this.modalsSetShow(null);

                setTimeout(() => {
                    this.modalsSetData({ type: 'affiliatesRedeem', data: { code: this.affiliatesCode } });
                    this.modalsSetShow('Captcha');
                }, 200);

                localStorage.removeItem('ref-code');
            }
        },
        computed: {
            ...mapGetters([
                'generalSettings', 
                'authUser',  
                'modalsData'
            ])
        },
        created() {
            if(this.modalsData !== null && this.modalsData.code !== undefined) {
                this.affiliatesCode = this.modalsData.code;
            }
        }
    }
</script>

<style scoped>
    .modal-redeem {
        width: 620px;
        position: relative;
        display: flex;
        justify-content: center;
        padding: 25px 0 44px 0;
        border-radius: 18px;
        background: linear-gradient(180deg, #1A1E29 0%, #1C202C 100%);
        border-top: 32px solid #1e212e;
        border-bottom: 32px solid #1e212e;
    }

    .modal-redeem .redeem-close {
        position: absolute;
        top: -14px;
        right: 12px;
    }

    .modal-redeem .redeem-close button {
        height: 27px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
    }

    .modal-redeem .redeem-close button:hover {
        color: #ffffff;
    }

    .modal-redeem .redeem-close button svg {
        margin-right: 8px;
        fill: #767c8b;
        transition: all 0.3s ease;
    }

    .modal-redeem .redeem-close button:hover svg {
        fill: #ffffff;
    }

    .modal-redeem .redeem-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .modal-redeem .content-title {
        text-transform: uppercase;
        font-size: 26px;
        font-weight: 800;
        color: #323743;
    }

    .modal-redeem .content-info {
        display: flex;
        align-items: center;
        margin-top: 7px;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
    }

    .modal-redeem .content-info span.info-code {
        margin: 0 5px;
        color: #fd3b31;
    }

    .modal-redeem .content-info img {
        width: 21px;
        margin-left: 10px;
        margin-right: 8px;
    }

    .modal-redeem .info-amount {
        font-size: 12px;
        font-weight: 700;
        color: #00C74d;
    }

    .modal-redeem .info-amount span {
        font-size: 15px;
    }

    .modal-redeem .content-input {
        width: 370px;
        position: relative;
        margin-top: 25px;
    }

    .modal-redeem .content-input input {
        width: 100%;
        height: 54px;
        padding: 0 17px;
        border-radius: 8px;
        font-size: 13px;
        font-weight: 700;
        color: #ffffff;
        background: #191e27;
        border: 1px solid #262c3a;
    }

    .modal-redeem .content-input input::placeholder {
        font-size: 13px;
        font-weight: 700;
        color: #596076;
    }

    .modal-redeem .content-button {
        width: 370px;
        margin-top: 18px;
    }

    .modal-redeem .content-button button.button-redeem {
        width: 100%;
        height: 55px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #00c74d;
        border-bottom: 2px solid #00732c;
    }

    .modal-redeem .content-button button.button-redeem:hover {
        background: #00de56;
    }

    @media only screen and (max-width: 650px) {

        .modal-redeem {
            width: 100%;
        }

    }

    @media only screen and (max-width: 450px) {

        .modal-redeem .redeem-content {
            padding: 0 20px;
        }

        .modal-redeem .content-input {
            width: 100%;
        }

        .modal-redeem .content-button {
            width: 100%;
        }

    }
</style>
