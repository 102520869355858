<template>
    <div class="profile-overview">
        <div class="overview-user">
            <AvatarImage v-bind:image="authUser.user.avatar" />
            <div class="user-info">
                <div class="info-username">
                    <span v-html="authUser.user.username"></span>
                    <span class="user-level">{{profileGetUserLevel}}</span>
                    <span class="user-rank" v-bind:class="{ 'rank-mod': authUser.user.rank === 'mod', 'rank-admin': authUser.user.rank === 'admin' }">{{authUser.user.rank.toUpperCase()}}</span>
                </div>
                <div class="info-level">
                    <div class="level-bar">
                        <div class="bar-progress" v-bind:style=" { 'width': profileGetUserLevelBetProgress + '%' }"></div>
                    </div>
                </div>
                <div class="info-actions">
                    <a v-bind:href="'https://steamcommunity.com/profiles/' + authUser.user.steamid" target="_blank">VIEW STEAM PROFILE</a>
                    <button v-on:click="authLogoutUser">
                        <svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.28516 13H6.52539V2.28516H2.28516V13ZM4.62109 6.88086H5.38281V7.64258H4.62109V6.88086Z" />
                            <path d="M8.42969 5.35742H9.95312V0H0V13H1.52344V1.52344H8.42969V5.35742Z" />
                            <path d="M8.42969 9.16602H9.95312V13H8.42969V9.16602Z" />
                            <path d="M13 7.26172L10.7148 4.97656V6.11914H7.28711V8.4043H10.7148V9.54688L13 7.26172Z" />
                        </svg>
                        LOGOUT
                    </button>
                </div>
            </div>
        </div>
        <div class="overview-stats">
            <div class="stats-element">
                <div class="element-title">TOTAL WAGERED</div>
                <div class="element-value">
                    <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                    <div class="value-amount">
                        <span>{{generalFormatAmount(authUser.user.stats.total.bet).split('.')[0]}}</span>.{{generalFormatAmount(authUser.user.stats.total.bet).split('.')[1]}}
                    </div>
                </div>
            </div>
            <div class="stats-element">
                <div class="element-title">TOTAL DEPOSITED</div>
                <div class="element-value">
                    <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                    <div class="value-amount">
                        <span>{{generalFormatAmount(authUser.user.stats.total.deposit).split('.')[0]}}</span>.{{generalFormatAmount(authUser.user.stats.total.deposit).split('.')[1]}}
                    </div>
                </div>
            </div>
            <div class="stats-element">
                <div class="element-title">TOTAL WITHDRAWN</div>
                <div class="element-value">
                    <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                    <div class="value-amount">
                        <span>{{generalFormatAmount(authUser.user.stats.total.withdraw).split('.')[0]}}</span>.{{generalFormatAmount(authUser.user.stats.total.withdraw).split('.')[1]}}
                    </div>
                </div>
            </div>
            <div class="stats-element element-profit" v-bind:class="{ 'element-negative': profileGetProfit < 0 }">
                <div class="element-title">TOTAL PROFIT</div>
                <div class="element-value">
                    <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                    <div class="value-amount">
                        <span>{{generalFormatAmount(profileGetProfit).split('.')[0]}}</span>.{{generalFormatAmount(profileGetProfit).split('.')[1]}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';
    import { mapGetters, mapActions } from 'vuex';
    import AvatarImage from '@/components/AvatarImage';

    export default {
        name: 'ProfileOverview',
        components: {
            AvatarImage
        },
        mixins: [
            mixins
        ],
        methods: {
            ...mapActions([
                'authLogoutUser'
            ])
        },
        computed: {
            ...mapGetters([
                'authUser'
            ]),
            profileGetUserLevel() {
                return Math.floor(Math.sqrt(this.authUser.user.stats.total.bet / 1000) / 4);
            },
            profileGetUserLevelBetProgress() {
                const levelBet = Math.pow((this.profileGetUserLevel + 1) * 4, 2) - Math.pow(this.profileGetUserLevel * 4, 2);
                const levelProgress = (this.authUser.user.stats.total.bet / 1000) - Math.pow(this.profileGetUserLevel * 4, 2);

                return (100 / levelBet) * levelProgress;
            },
            profileGetProfit() {
                return Math.floor(this.authUser.user.stats.total.withdraw -  this.authUser.user.stats.total.deposit);
            }
        }
    }
</script>

<style scoped>
    .profile-overview {
        width: 100%;
        border-radius: 8px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
    }

    .profile-overview .overview-user {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 30px 30px 0 30px;
    }

    .profile-overview .overview-user .avatar-image {
        width: 100px;
        height: 100px;
        border-radius: 12px;
    }

    .profile-overview .user-info {
        width: calc(100% - 100px);
        display: flex;
        flex-direction: column;
        padding-left: 20px;
    }

    .profile-overview .info-username {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .profile-overview .info-username span.user-level {
        margin-left: 5px;
        font-weight: 700;
        color: #00c74d;
    }

    .profile-overview .info-username span.user-rank {
        margin-left: 10px;
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .profile-overview .info-username span.user-rank.rank-mod {
        color: #00c74d;
    }

    .profile-overview .info-username span.user-rank.rank-admin {
        color: #fd3b31;
    }

    .profile-overview .info-level {
        width: 100%;
        margin-top: 12px;
    }

    .profile-overview .level-bar {
        width: 100%;
        height: 8px;
        display: flex;
        border-radius: 4px;
        background: #212732;
    }

    .profile-overview .bar-progress {
        height: 8px;
        border-radius: 4px;
        background: #00c74d;
    }

    .profile-overview .info-actions {
        display: flex;
        margin-top: 12px;
    }

    .profile-overview .info-actions a {
        height: 35px;
        display: flex;
        align-items: center;
        margin-right: 12px;
        padding: 0 16px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
        border-bottom: 2px solid #191e27;
    }

    .profile-overview .info-actions a:hover {
        color: #ffffff;
    }

    .profile-overview .info-actions button {
        height: 35px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
        border-bottom: 2px solid #191e27;
    }

    .profile-overview .info-actions button:hover {
        color: #ffffff;
    }

    .profile-overview .info-actions button svg {
        margin-right: 8px;
        fill: #767c8b;
        transition: all 0.3s ease;
    }

    .profile-overview .info-actions button:hover svg {
        fill: #ffffff;
    }

    .profile-overview .overview-stats {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 12px;
        padding: 25px 30px 25px 30px;
        border-top: 1px solid #212732;
    }

    .profile-overview .stats-element {
        width: calc(33.33% - 16px);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .profile-overview .stats-element.element-profit {
        width: 100%;
        margin-top: 16px;
    }

    .profile-overview .element-title {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .profile-overview .stats-element.element-profit .element-title {
        color: #00c74d;
    }

    .profile-overview .stats-element.element-profit.element-negative .element-title {
        color: #fd3b31;
    }

    .profile-overview .element-value {
        width: 100%;
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 8px;
        border-radius: 8px;
        font-size: 15px;
        font-weight: 600;
        color: #ffffff;
        background: #212732;
    }

    .profile-overview .stats-element.element-profit .element-value {
        color: #00c74d;
        background: rgba(0, 199, 77, 0.08);
    }

    .profile-overview .stats-element.element-profit.element-negative .element-value {
        color: #fd3b31;
        background: rgba(253, 59, 49, 0.08);
    }

    .profile-overview .element-value img {
        width: 21px;
        margin-right: 10px;
    }

    .profile-overview .value-amount {
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .profile-overview .stats-element.element-profit .value-amount {
        color: #00c74d;
    }

    .profile-overview .stats-element.element-profit.element-negative .value-amount {
        color: #fd3b31;
    }

    .profile-overview .value-amount span {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .profile-overview .stats-element.element-profit .value-amount span {
        color: #00c74d;
    }

    .profile-overview .stats-element.element-profit.element-negative .value-amount span {
        color: #fd3b31;
    }

    @media only screen and (max-width: 900px) {

        .profile-overview .stats-element {
            width: calc(50% - 8px);
            margin-top: 16px;
        }

        .profile-overview .stats-element:first-of-type {
            width: 100%;
            margin-top: 0;
        }

    }

    @media only screen and (max-width: 550px) {

        .profile-overview .overview-user {
            width: 100%;
            flex-direction: column;
            padding: 30px 15px 0 15px;
        }

        .profile-overview .user-info {
            width: 100%;
            align-items: center;
            padding-left: 0;
        }

        .profile-overview .info-username {
            margin-top: 20px;
        }

        .profile-overview .overview-stats {
            padding: 25px 15px 25px 15px;
        }

    }
</style>
