<template>
    <div class="cases-overview">
        <div class="overview-header">
            <div class="header-select">
                <button v-on:click="casesSetFilterSelect('featured')" v-bind:class="{ 'button-active': casesFilterSelect === 'featured' }">FEATURED</button>
                <button v-on:click="casesSetFilterSelect('50/50')" v-bind:class="{ 'button-active': casesFilterSelect === '50/50' }">50/50</button>
                <button v-on:click="casesSetFilterSelect('high')" v-bind:class="{ 'button-active': casesFilterSelect === 'high' }">HIGH RISK</button>
                <button v-on:click="casesSetFilterSelect('partner')" v-bind:class="{ 'button-active': casesFilterSelect === 'partner' }">PARTNER</button>
            </div>
            <div class="header-filter">
                <CasesFilterSearch />
            </div>
        </div>
        <div class="overview-content">
            <transition name="fade" mode="out-in">
                <div v-if="casesData.loading === true" class="content-loading" key="loading">

                    <div class="loading-placeholder"></div>
                    <div class="loading-placeholder"></div>
                    <div class="loading-placeholder"></div>
                    <div class="loading-placeholder"></div>
                    <div class="loading-placeholder"></div>
                    <div class="loading-placeholder"></div>

                </div>
                <div v-else-if="casesGetBoxes.length > 0" class="content-list" key="data">

                    <CasesBoxElement v-for="box of casesGetBoxes" v-bind:key="box._id" v-bind:box="box" />

                </div>
                <div v-else class="content-empty" key="empty">There are no cases.</div>
            </transition>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import CasesFilterSearch from '@/components/cases/CasesFilterSearch';
    import CasesBoxElement from '@/components/cases/CasesBoxElement';

    export default {
        name: 'CasesOverview',
        components: {
            CasesFilterSearch,
            CasesBoxElement
        },
        methods: {
            ...mapActions([
                'casesSetFilterSelect'
            ])
        },
        computed: {
            ...mapGetters([
                'casesFilterSelect',
                'casesFilterSearch',
                'casesData'
            ]),
            casesGetBoxes() {
                let boxes = [];

                for(const box of this.casesData.boxes) {
                    if(box.categories.includes(this.casesFilterSelect) === true && box.name.toLowerCase().includes(this.casesFilterSearch.toLowerCase().trim()) === true) {
                        boxes.push(box);
                    }
                }

                return boxes;
            }
        }
    }
</script>

<style scoped>
    .cases-overview {

    }

    .cases-overview .overview-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .cases-overview .header-select,
    .cases-overview .header-filter {
        display: flex;
        align-items: center;
    }

    .cases-overview .header-select button {
        height: 44px;
        margin-right: 12px;
        padding: 0 16px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
        box-shadow: 0px 2px 0px 0px #191e27;
        transition: all 0.3s ease;
    }

    .cases-overview .header-select button:last-of-type {
        margin-right: 0;
    }

    .cases-overview .header-select button:hover {
        color: #ffffff;
    }

    .cases-overview .header-select button.button-active {
        color: #fd3b31;
        background: #252129;
        box-shadow: 0px 0px 0px 1px #59272b;
    }

    .cases-overview .overview-content {
        width: 100%;
        margin-top: 26px;
    }

    .cases-overview .content-loading,
    .cases-overview .content-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .cases-overview .content-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .cases-overview .content-loading.fade-leave-to {
        opacity: 0;
    }

    .cases-overview .loading-placeholder {
        width: calc(16.66% - 13.33px);
        height: 267px;
        position: relative;
        margin-bottom: 16px;
        margin-right: 16px;
        border-radius: 18px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
        overflow: hidden;
    }

    .cases-overview .loading-placeholder:after {
        width: 100%;
        height: 100%;
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        animation-name: loading_animation;
        animation-duration: 1s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        background: linear-gradient(to right, #ffffff00 0%, rgba(255, 255, 255, .1) 50%, #ffffff00 100%);
    }

    .cases-overview .loading-placeholder:nth-child(6n) {
        margin-right: 0;
    }

    .cases-overview .content-empty {
        width: 100%;
        height: 220px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #626c7e;
    }

    .cases-overview .content-list.fade-enter-active,
    .cases-overview .content-empty.fade-enter-active {
        transition: opacity 0.5s;
    }

    .cases-overview .content-list.fade-enter-from,
    .cases-overview .content-empty.fade-enter-from {
        opacity: 0;
    }

    @keyframes loading_animation {
        0% { transform: translateX(-100%); }
        50% { transform: translateX(100%); }
        100% { transform: translateX(100%); }
    }

    @media only screen and (max-width: 1250px) {

        .cases-overview .loading-placeholder {
            width: calc(20% - 12.8px);       
        }

        .cases-overview .loading-placeholder:nth-child(6n) {
            margin-right: 16px; 
        }

        .cases-overview .loading-placeholder:nth-child(5n) {
            margin-right: 0; 
        }

    }

    @media only screen and (max-width: 1000px) {

        .cases-overview .loading-placeholder {
            width: calc(25% - 12px);       
        }

        .cases-overview .loading-placeholder:nth-child(5n) {
            margin-right: 16px; 
        }

        .cases-overview .loading-placeholder:nth-child(4n) {
            margin-right: 0; 
        }

    }

    @media only screen and (max-width: 750px) {

        .cases-overview .loading-placeholder {
            width: calc(33.33% - 10.66px);       
        }

        .cases-overview .loading-placeholder:nth-child(4n) {
            margin-right: 16px; 
        }

        .cases-overview .loading-placeholder:nth-child(3n) {
            margin-right: 0; 
        }

    }

    @media only screen and (max-width: 700px) { 

        .cases-overview .overview-header {
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
        }

        .cases-overview .header-select,
        .cases-overview .header-filter {
            width: 100%;
        }

        .cases-overview .header-select {
            flex-direction: column;
        }

        .cases-overview .header-select button {
            width: 100%;
            margin-bottom: 8px;
            margin-right: 0;
        }

        .cases-overview .header-select button:last-of-type {
            margin-bottom: 0;
        }

        .cases-overview .header-filter {
            margin-top: 15px;
        }

    }

    @media only screen and (max-width: 550px) {

        .cases-overview .loading-placeholder {
            width: calc(50% - 8px);       
        }

        .cases-overview .loading-placeholder:nth-child(3n) {
            margin-right: 16px; 
        }

        .cases-overview .loading-placeholder:nth-child(2n) {
            margin-right: 0; 
        }

    }
</style>