<template>
    <div class="modal-chat-user">
        <div class="user-close">
            <button v-on:click="modalsSetShow(null)">
                <svg width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0005 1.22222L9.77827 0L5.50019 4.27808L1.22222 0.000111474L0 1.22233L4.27797 5.5003L0.000501987 9.77776L1.22272 11L5.50019 6.72252L9.77776 11.0001L11 9.77788L6.72241 5.5003L11.0005 1.22222Z" />
                </svg>
                CLOSE
            </button>
        </div>
        <div class="user-content">
            <div class="content-user" v-bind:class="[ 'user-' + modalsData.user.rank ]">
                <AvatarImage v-bind:image="modalsData.user.avatar" />
                <div class="user-rank">
                    <font-awesome-icon v-if="['system', 'admin', 'mod'].includes(modalsData.user.rank) !== true" :icon="['fas', 'star']" />
                    {{ ['admin', 'mod'].includes(modalsData.user.rank) === true ? modalsData.user.rank.toUpperCase() : modalsData.user.level }}
                </div>
                <span v-html="modalsData.user.username" class="user-username"></span>
            </div>
            <div class="content-actions">
                <a v-bind:href="'https://steamcommunity.com/profiles/' + modalsData.user.steamid" target="_blank">VIEW STEAM PROFILE</a>
                <button v-on:click="modalTipButton()" class="button-send">SEND TIP</button>
            </div>
            <div class="content-stats">
                <div class="stats-element">
                    <div class="element-title">TOTAL WAGERED</div>
                    <div v-if="modalsData.user.stats.total !== undefined" class="element-value">
                        <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                        <div class="value-amount">
                            <span>{{generalFormatAmount(modalsData.user.stats.total.bet).split('.')[0]}}</span>.{{generalFormatAmount(modalsData.user.stats.total.bet).split('.')[1]}}
                        </div>
                    </div>
                    <div v-else class="element-hidden">HIDDEN</div>
                </div>
                <div class="stats-element">
                    <div class="element-title">TOTAL DEPOSITED</div>
                    <div v-if="modalsData.user.stats.total !== undefined" class="element-value">
                        <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                        <div class="value-amount">
                            <span>{{generalFormatAmount(modalsData.user.stats.total.deposit).split('.')[0]}}</span>.{{generalFormatAmount(modalsData.user.stats.total.deposit).split('.')[1]}}
                        </div>
                    </div>
                    <div v-else class="element-hidden">HIDDEN</div>
                </div>
                <div class="stats-element">
                    <div class="element-title">TOTAL WITHDRAWN</div>
                    <div v-if="modalsData.user.stats.total !== undefined" class="element-value">
                        <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                        <div class="value-amount">
                            <span>{{generalFormatAmount(modalsData.user.stats.total.withdraw).split('.')[0]}}</span>.{{generalFormatAmount(modalsData.user.stats.total.withdraw).split('.')[1]}}
                        </div>
                    </div>
                    <div v-else class="element-hidden">HIDDEN</div>
                </div>
                <div class="stats-element element-profit" v-bind:class="{ 'element-negative': modalsData.user.stats.total !== undefined && modalGetUserProfit < 0 }">
                    <div class="element-title">TOTAL PROFIT</div>
                    <div v-if="modalsData.user.stats.total !== undefined" class="element-value">
                        <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                        <div class="value-amount">
                            <span>{{generalFormatAmount(modalGetUserProfit).split('.')[0]}}</span>.{{generalFormatAmount(modalGetUserProfit).split('.')[1]}}
                        </div>
                    </div>
                    <div v-else class="element-hidden">HIDDEN</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';
    import { mapGetters, mapActions } from 'vuex';
    import AvatarImage from '@/components/AvatarImage';

    export default {
        name: 'ModalChatUser',
        components: {
            AvatarImage
        },
        mixins: [
            mixins
        ],
        methods: {
            ...mapActions([
                'modalsSetShow', 
                'modalsSetData'
            ]),
            modalTipButton() {
                this.modalsSetShow(null);

                setTimeout(() => {
                    this.modalsSetData({ user: this.modalsData.user });
                    this.modalsSetShow('Tip');
                }, 200);
            }
        },
        computed: {
            ...mapGetters([
                'authUser',  
                'modalsData'
            ]),
            modalGetUserProfit() {
                return Math.floor(this.modalsData.user.stats.total.withdraw - this.modalsData.user.stats.total.deposit);
            }
        }
    }
</script>

<style scoped>
    .modal-chat-user {
        width: 900px;
        position: relative;
        display: flex;
        justify-content: center;
        padding: 70px 0 35px 0;
        border-radius: 18px;
        background: linear-gradient(180deg, #1A1E29 0%, #1C202C 100%);
        border-top: 32px solid #1e212e;
        border-bottom: 32px solid #1e212e;
    }

    .modal-chat-user .user-close {
        position: absolute;
        top: -14px;
        right: 12px;
    }

    .modal-chat-user .user-close button {
        height: 27px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
    }

    .modal-chat-user .user-close button:hover {
        color: #ffffff;
    }

    .modal-chat-user .user-close button svg {
        margin-right: 8px;
        fill: #767c8b;
        transition: all 0.3s ease;
    }

    .modal-chat-user .user-close button:hover svg {
        fill: #ffffff;
    }

    .modal-chat-user .user-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .modal-chat-user .content-user {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .modal-chat-user .content-user .avatar-image {
        width: 100px;
        height: 100px;
        position: absolute;
        top: -120px;
        left: 50%;
        transform: translate(-50%, 0);
        border-radius: 12px;
    }

    .modal-chat-user .content-user span.user-rank {
        margin-right: 5px;
    }

    .modal-chat-user .user-rank {
        height: 19px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        padding: 0 5px;
        border-radius: 3px;
        font-size: 10px;
        font-weight: 700;
        color: #ffffff;
        background: #a1aeb5;
    }

    .modal-chat-user .content-user.user-mod .user-rank {
        background: #00c74d;
    }

    .modal-chat-user .content-user.user-admin .user-rank {
        background: #fd3b31;
    }

    .modal-chat-user .user-rank svg {
        width: 11px;
        margin-right: 5px;
        margin-bottom: 1px;
    }

    .modal-chat-user .content-actions {
        display: flex;
        margin-top: 12px;
    }

    .modal-chat-user .content-actions a {
        height: 35px;
        display: flex;
        align-items: center;
        margin-right: 12px;
        padding: 0 16px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
        border-bottom: 2px solid #191e27;
    }

    .modal-chat-user .content-actions a:hover {
        color: #ffffff;
    }

    .modal-chat-user .content-actions button.button-send {
        height: 35px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        color: #ffffff;
        background: #00c74d;
        border-bottom: 2px solid #00732c;
    }

    .modal-chat-user .content-actions button.button-send:hover {
        background: #00de56;
    }

    .modal-chat-user .content-stats {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 39px;
        padding: 0 90px;
    }

    .modal-chat-user .stats-element.element-profit {
        width: 100%;
        margin-top: 16px;
    }

    .modal-chat-user .stats-element {
        width: calc(33.33% - 16px);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .modal-chat-user .stats-element:last-of-type {
        margin-right: 0;
    }

    .modal-chat-user .element-title {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .modal-chat-user .stats-element.element-profit .element-title {
        color: #00c74d;
    }

    .modal-chat-user .stats-element.element-profit.element-negative .element-title {
        color: #fd3b31;
    }

    .modal-chat-user .element-value,
    .modal-chat-user .element-hidden {
        width: 100%;
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 8px;
        border-radius: 8px;
        font-size: 15px;
        font-weight: 600;
        color: #ffffff;
        background: #212732;
    }

    .modal-chat-user .stats-element.element-profit .element-value,
    .modal-chat-user .stats-element.element-profit .element-hidden {
        color: #00c74d;
        background: rgba(0, 199, 77, 0.08);
    }

    .modal-chat-user .stats-element.element-profit.element-negative .element-value {
        color: #fd3b31;
        background: rgba(253, 59, 49, 0.08);
    }

    .modal-chat-user .element-hidden {
        font-style: italic;
    }

    .modal-chat-user .element-value img {
        width: 21px;
        margin-right: 10px;
    }

    .modal-chat-user .value-amount {
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .modal-chat-user .stats-element.element-profit .value-amount {
        color: #00c74d;
    }

    .modal-chat-user .stats-element.element-profit.element-negative .value-amount {
        color: #fd3b31;
    }

    .modal-chat-user .value-amount span {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .modal-chat-user .stats-element.element-profit .value-amount span {
        color: #00c74d;
    }

    .modal-chat-user .stats-element.element-profit.element-negative .value-amount span {
        color: #fd3b31;
    }

    @media only screen and (max-width: 920px) {

        .modal-chat-user {
            width: 100%;
        }

    }

    @media only screen and (max-width: 700px) {

        .modal-chat-user .content-stats {
            padding: 0 44px;
        }

    }

    @media only screen and (max-width: 600px) {

        .modal-chat-user .content-stats {
            flex-wrap: wrap;
        }

        .modal-chat-user .stats-element {
            width: calc(50% - 12px);
            margin-top: 16px;
        }

        .modal-chat-user .stats-element:first-of-type {
            width: 100%;
            margin-right: 0;
        }

    }

    @media only screen and (max-width: 500px) {

        .modal-chat-user .content-stats {
            padding: 0 20px;
        }

    }
</style>
