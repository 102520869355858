const state = {
    affiliatesData: {
        data: null,
        loading: false
    },
}

const getters = {
    affiliatesData: state => state.affiliatesData
}

const mutations = {
    affiliates_set_data(state, data) {
        state.affiliatesData.data = data.data;
    },
    affiliates_set_data_loading(state, status) {
        state.affiliatesData.loading = status;
    }
}

const actions = {
    affiliatesGetDataSocket({ getters, commit, dispatch }, data) {
        if(getters.affiliatesData.loading === true) { return; }
        commit('affiliates_set_data_loading', true);

        getters.socketClient.emit('general:affiliate:getData', data, (res) => {
            if(res.success === true) {
                commit('affiliates_set_data', res);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('affiliates_set_data_loading', false);
        });
    },
    affiliatesSendCodeSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AffiliatesCode');

        getters.socketClient.emit('general:affiliate:sendCode', data, (res) => {
            if(res.success === true) {
                commit('affiliates_set_data', res);
                dispatch('notificationShow', { type: 'success', message: 'You have successfully updated your affiliate code.' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    affiliatesSendRedeemCodeSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AffiliatesRedeem');

        getters.socketClient.emit('general:affiliate:sendClaimCode', data, (res) => {
            if(res.success === true) {
                dispatch('modalsSetShow', null);
                dispatch('notificationShow', { type: 'success', message: 'You have successfully redeemed affiliate code.' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    affiliatesSendClaimEarningsSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AffiliatesClaim');

        getters.socketClient.emit('general:affiliate:sendClaimEarnings', data, (res) => {
            if(res.success === true) {
                commit('affiliates_set_data', res);
                dispatch('notificationShow', { type: 'success', message: 'You have successfully claimed your affiliate earnings.' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    }
}

const affiliates = {
    state,
    mutations,
    actions,
    getters
}

export default affiliates;
