<template>
    <router-link v-bind:to="game" v-bind:class="['home-game-element', 'element-' + game]">
        <div class="element-banner">
            <img v-bind:src="require('@/assets/img/games/backup/' + game + '.webp')" />
        </div>
        <div class="element-text">
            <component v-bind:is="'Icon' + game.charAt(0).toUpperCase() + game.slice(1)" />
            {{ game.toUpperCase() }}
            <div v-if="type !== ''" v-bind:class="[ 'text-' + type ]">{{ type.toUpperCase() }}</div>
        </div>
    </router-link>
</template>

<script>
    import IconBattles from '@/components/icons/IconBattles';
    import IconRoulette from '@/components/icons/IconRoulette';
    import IconCases from '@/components/icons/IconCases';
    import IconBlackjack from '@/components/icons/IconBlackjack';
    import IconMines from '@/components/icons/IconMines';
    import IconTowers from '@/components/icons/IconTowers';
    import IconUpgrader from '@/components/icons/IconUpgrader';

    export default {
        name: 'HomeGameElement',
        components: {
            IconBattles,
            IconRoulette,
            IconCases,
            IconBlackjack,
            IconMines,
            IconTowers,
            IconUpgrader
        },
        props: [
            'game',
            'type'
        ]
    }
</script>

<style scoped>
    a.home-game-element {
        width: calc(25% - 11.25px);
        display: flex;
        flex-direction: column;
        margin-right: 15px;
        margin-top: 15px;
        padding: 5px 5px 0 5px;
        border-radius: 8px;
        background: #212732;
    }

    a.home-game-element.element-battles,
    a.home-game-element.element-roulette,
    a.home-game-element.element-cases {
        width: calc(33.33% - 10px);
    }

    a.home-game-element:nth-child(3),
    a.home-game-element:nth-child(7) {
        margin-right: 0;
    }

    a.home-game-element .element-banner {
        width: 100%;
        height: 170px;
        display: flex;
        align-items: center;
        border-radius: 7px 7px 0 0;
        background: #191e27;
        overflow: hidden;
    }

    a.home-game-element.element-battles .element-banner,
    a.home-game-element.element-roulette .element-banner,
    a.home-game-element.element-cases .element-banner {
        height: 200px;
    }

    a.home-game-element .element-banner img {
        width: 100%;
        transition: transform 0.3s ease;
    }

    a.home-game-element:hover .element-banner img {
        transform: scale(1.04);
    }

    a.home-game-element .element-text {
        height: 38px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
    }

    a.home-game-element .element-text svg {
        width: 18px;
        margin-right: 10px;
        fill: #fd3b31;
    }

    a.home-game-element.element-cases .element-text svg,
    a.home-game-element.element-mines .element-text svg {
        width: 21px;
    }

    a.home-game-element .text-hot,
    a.home-game-element .text-new {
        height: 20px;
        display: flex;
        align-items: center;
        margin-left: 8px;
        padding: 0 4px;
        border-radius: 3px;
        font-size: 11px;
        font-weight: 700;
        color: #ffffff;
    }

    a.home-game-element .text-hot {
        background: #fd3b31;
    }

    a.home-game-element .text-new {
        background: #00c74d;
    }

    @media only screen and (max-width: 600px) {

        a.home-game-element {
            width: 100%!important;
            margin-right: 0;
        }

        a.home-game-element .element-banner {
            height: 150px!important;
        }

    }
</style>