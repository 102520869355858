import router from '../../../router';

const state = {
    adminFilterSearch: '',
    adminFilterStats: 'total',
    adminFilterSort: 'newest',
    adminFilterSelect: 'all',
    adminStatsData: {
        data: null,
        loading: false
    },
    adminUserList: {
        data: null,
        count: null,
        loading: false,
        page: 1
    },
    adminUserData: {
        data: null,
        loading: false
    },
    adminUserTransactionList: {
        data: null,
        count: null,
        loading: false,
        page: 1
    },
    adminTransactionList: {
        data: null,
        count: null,
        loading: false,
        page: 1
    },
    adminItemList: {
        data: null,
        count: null,
        loading: false,
        page: 1
    },
    adminBoxList: {
        data: null,
        count: null,
        selected: [],
        loading: false,
        page: 1
    },
    adminCodeList: {
        data: null,
        count: null,
        loading: false,
        page: 1,
        generated: null
    },
    adminLeaderboardList: {
        data: null,
        count: null,
        loading: false,
        page: 1
    },
    adminReportList: {
        data: null,
        count: null,
        loading: false,
        page: 1
    },
}

const getters = {
    adminFilterSearch: state => state.adminFilterSearch,
    adminFilterStats: state => state.adminFilterStats,
    adminFilterSort: state => state.adminFilterSort,
    adminFilterSelect: state => state.adminFilterSelect,
    adminStatsData: state => state.adminStatsData,
    adminUserList: state => state.adminUserList,
    adminUserData: state => state.adminUserData,
    adminUserTransactionList: state => state.adminUserTransactionList,
    adminTransactionList: state => state.adminTransactionList,
    adminItemList: state => state.adminItemList,
    adminBoxList: state => state.adminBoxList,
    adminCodeList: state => state.adminCodeList,
    adminLeaderboardList: state => state.adminLeaderboardList,
    adminReportList: state => state.adminReportList
}

const mutations = {
    admin_set_filter_search(state, value) {
        state.adminFilterSearch = value;
    },
    admin_set_filter_stats(state, value) {
        state.adminFilterStats = value;
    },
    admin_set_filter_sort(state, value) {
        state.adminFilterSort = value;
    },
    admin_set_filter_select(state, value) {
        state.adminFilterSelect = value;
    },
    admin_set_stats_data(state, data) {
        state.adminStatsData.data = data.stats;
    },
    admin_set_stats_data_loading(state, status) {
        state.adminStatsData.loading = status;
    },
    admin_set_user_list(state, data) {
        state.adminUserList.data = data.users;
        state.adminUserList.count = data.count;
    },
    admin_set_user_list_loading(state, status) {
        state.adminUserList.loading = status;
    },
    admin_set_user_list_page(state, page) {
        state.adminUserList.page = page;
    },
    admin_set_user_data(state, data) {
        state.adminUserData.data = data.user;
    },
    admin_set_user_data_loading(state, status) {
        state.adminUserData.loading = status;
    },
    admin_set_user_transaction_list(state, data) {
        state.adminUserTransactionList.data = data.transactions;
        state.adminUserTransactionList.count = data.count;
    },
    admin_set_user_transaction_list_loading(state, status) {
        state.adminUserTransactionList.loading = status;
    },
    admin_set_user_transaction_list_page(state, page) {
        state.adminUserTransactionList.page = page;
    },
    admin_set_transaction_list(state, data) {
        state.adminTransactionList.data = data.transactions;
        state.adminTransactionList.count = data.count;
    },
    admin_set_transaction_list_loading(state, status) {
        state.adminTransactionList.loading = status;
    },
    admin_set_transaction_list_page(state, page) {
        state.adminTransactionList.page = page;
    },
    admin_set_item_list(state, data) {
        state.adminItemList.data = data.items;
        state.adminItemList.count = data.count;
    },
    admin_update_item_list_data(state, item) {
        state.adminItemList.data.splice(state.adminItemList.data.findIndex((element) => element._id === item._id), 1, item);
    },
    admin_set_item_list_loading(state, status) {
        state.adminItemList.loading = status;
    },
    admin_set_item_list_page(state, page) {
        state.adminItemList.page = page;
    },
    admin_set_box_list(state, data) {
        state.adminBoxList.data = data.boxes;
        state.adminBoxList.count = data.count;
    },
    admin_update_box_list_data(state, box) {
        state.adminBoxList.data.splice(state.adminBoxList.data.findIndex((element) => element._id === box._id), 1, box);
    },
    admin_add_box_list_selected(state, item) {
        state.adminBoxList.selected.push(item);
    },
    admin_remove_box_list_selected(state, item) {
        state.adminBoxList.selected.splice(state.adminBoxList.selected.findIndex((element) => element._id === item._id), 1);
    },
    admin_empty_box_list_selected(state) {
        state.adminBoxList.selected = [];
    },
    admin_set_box_list_loading(state, status) {
        state.adminBoxList.loading = status;
    },
    admin_set_box_list_page(state, page) {
        state.adminBoxList.page = page;
    },
    admin_set_code_list(state, data) {
        state.adminCodeList.data = data.codes;
        state.adminCodeList.count = data.count;
    },
    admin_remove_code_list_data(state, code) {
        state.adminCodeList.data.splice(state.adminCodeList.data.findIndex((element) => element._id === code._id), 1);
    },
    admin_set_code_list_loading(state, status) {
        state.adminCodeList.loading = status;
    },
    admin_set_code_list_page(state, page) {
        state.adminCodeList.page = page;
    },
    admin_set_code_list_generated(state, codes) {
        state.adminCodeList.generated = codes;
    },
    admin_set_leaderboard_list(state, data) {
        state.adminLeaderboardList.data = data.leaderboards;
        state.adminLeaderboardList.count = data.count;
    },
    admin_update_leaderboard_list_data(state, leaderboard) {
        state.adminLeaderboardList.data.splice(state.adminLeaderboardList.data.findIndex((element) => element._id === leaderboard._id), 1, leaderboard);
    },
    admin_set_leaderboard_list_loading(state, status) {
        state.adminLeaderboardList.loading = status;
    },
    admin_set_leaderboard_list_page(state, page) {
        state.adminLeaderboardList.page = page;
    },
    admin_set_report_list(state, data) {
        state.adminReportList.data = data.reports;
        state.adminReportList.count = data.count;
    },
    admin_set_report_list_loading(state, status) {
        state.adminReportList.loading = status;
    },
    admin_set_report_list_page(state, page) {
        state.adminReportList.page = page;
    }
}

const actions = {
    adminSetUserListPage({ commit }, page) {
        commit('admin_set_user_list_page', page);
    },
    adminSetUserTransactionListPage({ commit }, page) {
        commit('admin_set_user_transaction_list_page', page);
    },
    adminSetTransactionListPage({ commit }, page) {
        commit('admin_set_transaction_list_page', page);
    },
    adminSetItemListPage({ commit }, page) {
        commit('admin_set_item_list_page', page);
    },
    adminSetBoxListPage({ commit }, page) {
        commit('admin_set_box_list_page', page);
    },
    adminAddBoxListSelected({ commit }, item) {
        commit('admin_add_box_list_selected', item);
    },
    adminRemoveBoxListSelected({ commit }, item) {
        commit('admin_remove_box_list_selected', item);
    },
    adminEmptyBoxListSelected({ commit }) {
        commit('admin_empty_box_list_selected');
    },
    adminSetCodeListPage({ commit }, page) {
        commit('admin_set_code_list_page', page);
    },
    adminEmptyCodeListGenerated({ commit }) {
        commit('admin_set_code_list_generated', null);
    },
    adminSetLeaderboardListPage({ commit }, page) {
        commit('admin_set_leaderboard_list_page', page);
    },
    adminSetReportListPage({ commit }, page) {
        commit('admin_set_report_list_page', page);
    },
    adminSetFilterSearch({ commit }, value) {
        commit('admin_set_filter_search', value);
    },
    adminSetFilterStats({ commit }, value) {
        commit('admin_set_filter_stats', value);
    },
    adminSetFilterSort({ commit }, value) {
        commit('admin_set_filter_sort', value);
    },
    adminSetFilterSelect({ commit }, value) {
        commit('admin_set_filter_select', value);
    },
    adminEmptyTicketData({ commit }) {
        commit('admin_set_ticket_data_data', null);
    },
    adminGetReportListSocket({ getters, commit, dispatch }, data) {
        if(getters.adminReportList.loading === true) { return; }
        commit('admin_set_report_list_loading', true);

        getters.socketClient.emit('admin:stats:getList', data, (res) => {
            if(res.success === true) {
                commit('admin_set_report_list', res);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('admin_set_report_list_loading', false);
        });
    },
    adminGetStatsDataSocket({ getters, commit, dispatch }, data) {
        if(getters.adminStatsData.loading === true) { return; }
        commit('admin_set_stats_data_loading', true);

        getters.socketClient.emit('admin:stats:getData', data, (res) => {
            if(res.success === true) {
                commit('admin_set_stats_data', res);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('admin_set_stats_data_loading', false);
        });
    },
    adminGetUserListSocket({ getters, commit, dispatch }, data) {
        if(getters.adminUserList.loading === true) { return; }
        commit('admin_set_user_list_loading', true);

        getters.socketClient.emit('admin:user:getList', data, (res) => {
            if(res.success === true) {
                commit('admin_set_user_list', res);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('admin_set_user_list_loading', false);
        });
    },
    adminGetUserDataSocket({ getters, commit, dispatch }, data) {
        if(getters.adminUserData.loading === true) { return; }
        commit('admin_set_user_data_loading', true);

        getters.socketClient.emit('admin:user:getData', data, (res) => {
            if(res.success === true) {
                commit('admin_set_user_data', res);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('admin_set_user_data_loading', false);
        });
    },
    adminGetUserTransactionListSocket({ getters, commit, dispatch }, data) {
        if(getters.adminUserTransactionList.loading === true) { return; }
        commit('admin_set_user_transaction_list_loading', true);

        getters.socketClient.emit('admin:user:getTransactionList', data, (res) => {
            if(res.success === true) {
                commit('admin_set_user_transaction_list', res);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('admin_set_user_transaction_list_loading', false);
        });
    },
    adminGetTransactionListSocket({ getters, commit, dispatch }, data) {
        if(getters.adminTransactionList.loading === true) { return; }
        commit('admin_set_transaction_list_loading', true);

        getters.socketClient.emit('admin:transaction:getList', data, (res) => {
            if(res.success === true) {
                commit('admin_set_transaction_list', res);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('admin_set_transaction_list_loading', false);
        });
    },
    adminGetItemListSocket({ getters, commit, dispatch }, data) {
        if(getters.adminItemList.loading === true) { return; }
        commit('admin_set_item_list_loading', true);

        getters.socketClient.emit('admin:item:getList', data, (res) => {
            if(res.success === true) {
                commit('admin_set_item_list', res);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('admin_set_item_list_loading', false);
        });
    },
    adminGetBoxListSocket({ getters, commit, dispatch }, data) {
        if(getters.adminBoxList.loading === true) { return; }
        commit('admin_set_box_list_loading', true);

        getters.socketClient.emit('admin:box:getList', data, (res) => {
            if(res.success === true) {
                commit('admin_set_box_list', res);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('admin_set_box_list_loading', false);
        });
    },
    adminGetCodeListSocket({ getters, commit, dispatch }, data) {
        if(getters.adminCodeList.loading === true) { return; }
        commit('admin_set_code_list_loading', true);

        getters.socketClient.emit('admin:code:getList', data, (res) => {
            if(res.success === true) {
                commit('admin_set_code_list', res);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('admin_set_code_list_loading', false);
        });
    },
    adminGetLeaderboardListSocket({ getters, commit, dispatch }, data) {
        if(getters.adminLeaderboardList.loading === true) { return; }
        commit('admin_set_leaderboard_list_loading', true);

        getters.socketClient.emit('admin:leaderboard:getList', data, (res) => {
            if(res.success === true) {
                commit('admin_set_leaderboard_list', res);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('admin_set_leaderboard_list_loading', false);
        });
    },
    adminSendSettingsValuelSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AdminSettings' + data.setting.split('.')[0].charAt(0).toUpperCase() + data.setting.split('.')[0].slice(1, data.setting.length - 1));

        getters.socketClient.emit('admin:setting:sendValue', data, (res) => {
            if(res.success === true) {
                dispatch('notificationShow', { type: 'success', message: 'You have successfully set the general settings.' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    adminSendUserRankSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AdminUserRank');

        getters.socketClient.emit('admin:user:sendRank', data, (res) => {
            if(res.success === true) {
                commit('admin_set_user_data', res);

                dispatch('notificationShow', { type: 'success', message: 'Your requested action was successfully processed.' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    adminSendUserBalanceSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AdminUserBalance');

        getters.socketClient.emit('admin:user:sendBalance', data, (res) => {
            if(res.success === true) {
                commit('admin_set_user_data', res);

                dispatch('notificationShow', { type: 'success', message: 'Your requested action was successfully processed.' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    adminSendUserCodeSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AdminUserCode');

        getters.socketClient.emit('admin:user:sendCode', data, (res) => {
            if(res.success === true) {
                commit('admin_set_user_data', res);

                dispatch('notificationShow', { type: 'success', message: 'Your requested action was successfully processed.' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    adminSendUserBlockSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AdminUserBlockTip');

        getters.socketClient.emit('admin:user:sendBlock', data, (res) => {
            if(res.success === true) {
                commit('admin_set_user_data', res);

                dispatch('notificationShow', { type: 'success', message: 'Your requested action was successfully processed.' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    adminSendUserMuteSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AdminUserMute');

        getters.socketClient.emit('admin:user:sendMute', data, (res) => {
            if(res.success === true) {
                if(getters.adminUserData.data !== null) { commit('admin_set_user_data', res); }

                dispatch('modalsSetShow', null);
                dispatch('notificationShow', { type: 'success', message: 'Your requested action was successfully processed.' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    adminSendUserBanSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AdminUserBan');

        getters.socketClient.emit('admin:user:sendBan', data, (res) => {
            if(res.success === true) {
                if(getters.adminUserData.data !== null) { commit('admin_set_user_data', res); }

                dispatch('modalsSetShow', null);
                dispatch('notificationShow', { type: 'success', message: 'Your requested action was successfully processed.' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    adminSendItemPriceSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AdminItemPrice');

        getters.socketClient.emit('admin:item:sendPrice', data, (res) => {
            if(res.success === true) {
                commit('admin_update_item_list_data', res.item);
                dispatch('notificationShow', { type: 'success', message: 'You have successfully updated the items.' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    adminSendItemAcceptedSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AdminItemAccepted');

        getters.socketClient.emit('admin:item:sendAccepted', data, (res) => {
            if(res.success === true) {
                commit('admin_update_item_list_data', res.item);
                dispatch('notificationShow', { type: 'success', message: 'You have successfully updated the items.' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    adminSendBoxCreateSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AdminBoxCreate');

        getters.socketClient.emit('admin:box:sendCreate', data, (res) => {
            if(res.success === true) {
                
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    adminSendBoxPauseSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AdminBoxPause');

        getters.socketClient.emit('admin:box:sendPause', data, (res) => {
            if(res.success === true) {
                commit('admin_update_box_list_data', res.box);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    adminSendCodeCreateSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AdminCodeCreate');

        getters.socketClient.emit('admin:code:sendCreate', data, (res) => {
            if(res.success === true) {
                commit('admin_set_code_list_generated', res.codes);

                dispatch('notificationShow', { type: 'success', message: 'You have successfully created a new gift code.' });
                //router.push({ name: 'AdminCodes' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    adminSendCodeRemoveSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AdminCodeRemove');

        getters.socketClient.emit('admin:code:sendRemove', data, (res) => {
            if(res.success === true) {
                dispatch('notificationShow', { type: 'success', message: 'You have successfully removed the code.' });
                commit('admin_remove_code_list_data', res.code);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    adminSendLeaderboardCreateSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AdminLeaderboardCreate');

        getters.socketClient.emit('admin:leaderboard:sendCreate', data, (res) => {
            if(res.success === true) {
                
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    adminSendLeaderboardCancelSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AdminLeaderboardCancel');

        getters.socketClient.emit('admin:leaderboard:sendCancel', data, (res) => {
            if(res.success === true) {
                console.log(res);
                commit('admin_update_leaderboard_list_data', res.leaderboard);

                router.push({ name: 'AdminLeaderboards' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    }
}

const admin = {
    state,
    mutations,
    actions,
    getters
}

export default admin;
